@import "../../../assets/scss/Variables.scss";

.modal-final-address {
  min-width: 100%;

  .modal-final-address--header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    label {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      width: 100%;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 12px;
      text-align: center;
    }
  }

  .modal-final-address--body {
    gap: 5px;
    width: 100%;
    display: flex;
    padding: 10px;
    font-size: 14px;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .modal-final-address--body--input {
      width: 100%;
      text-align: left;

      input {
        text-align: left;
      }

      label {
        color: #6d6d6d;
      }
    }

    .modal-final-address--body--number-complement {
      gap: 5px;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;

      input:first-child {
        width: 30%;
        text-align: center;
      }
    }

    .modal-final-address--body--neighbourhood {
      gap: 5px;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: center;

      label {
        margin: 0;
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $border-color;
        border-radius: $button-border-radius;
        padding: 10px;
        text-align: center;
        cursor: pointer;
      }
    }

    .modal-final-address--body--city-state {
      gap: 5px;
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .modal-final-address--footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
}

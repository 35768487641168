
.modal-ingredients {

  .modal-content{
    display: flex;
    flex-direction: column;
    height: 100vh;
    border-radius: unset !important;

    .modal-body{
      overflow: auto;
    }
  }
  
  .card-optional {
    cursor: default;
    
    .card-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      
      .number-control {
        flex: 1 1 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        .button-icon {
          padding: 1px;
          display: flex;
          cursor: pointer;
          font-size: 10px;
          flex-direction: column;
        }
        
        .button-number {
          margin: 0;
          color: #FFFFFF;
          font-weight: 600;
          padding: 4px 8px;
          border-radius: 3px;
          pointer-events: none;
          font-size: 11px;
          background-color: #1B1E21;
        }
      }
      
      .card-body-content {
        flex: 1 1 90%;
        display: flex;
        cursor: pointer;
        margin-left: 10px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }
}
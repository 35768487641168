.modal-minimap-address {
  .modal-minimap-address--header {
    .modal-title {
      width: 100%;
      text-align: center;
      font-weight: 700;
    }
  }

  .modal-minimap-address--overlay {
    display: none !important;
    background-color: #d00505de;
    position: absolute;
    top: 16px;
    left: 16px;
    right: 16px;
    border-radius: 20px;
    padding: 10px;
    color: #fff;
    font-size: 14px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
      margin: 0;
      padding: 0;
    }
    
    &.show {
      display: block !important;
    }
  }

  .modal-minimap-address--body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .modal-minimap-address--body--top {
      width: 100%;
      font-size: 14px;
      color: #696969;
      text-align: center;
    }

    .modal-minimap-address--body--map {
      position: relative;
      max-width: 450px;
      width: 100%;

      .modal-minimap-address--body--map--overlay {
        position: absolute;
        z-index: 999;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #696969;
        
        p {
          font-size: 24px;
          margin-top: 20px;
          font-weight: 700;
        }
      }
    }

    .modal-minimap-address--body--center {
      width: 100%;
      font-size: 22px;
      color: #696969;
      text-align: center;
      margin-top: 40px;
    }
  }

  .modal-minimap-address--footer {
    justify-content: center;
    align-items: center;
    .modal-minimap-address--footer--content {
      max-width: 450px;
      width: 100%;
      justify-content: center;
      align-items: center;

    }
    .modal-minimap-address--footer--flex {
      width: 100%;
      display: flex;
      gap: 10px;
      margin-top: 10px;
    }
  }
}
@import "../../assets/scss/Variables";

.product-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .product-item--data {
    display: flex;
    width: 100%;
    height: 100%;

    align-items: flex-start;
    justify-content: center;
    font-size: 12px;
    padding: 10px;
    line-height: 14px;

    .product-item--data--left {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      //border: 1px solid red;

      .product-item--data--left--edit-button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        top: -2px;
        right: -2px;
        box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);

        background-color: #fff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

      .product-item--data--left--image {
        width: 60px;
        height: 60px;
        border-radius: $button-border-radius;
      }
    }

    .product-item--data--center {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      justify-content: center;
      padding-left: 10px;

      .product-item--data--center--title {
        font-weight: 700;
        text-align: left;
        text-wrap: normal;
      }

      .product-item--data--center--size {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;

        .product-item--data--center--size--value {
          font-size: 9px;
        }
      }
      .product-item--data--center--flavour {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;

        .product-item--data--center--flavour--value {
          font-size: 9px;
        }

        .product-item--data--center--flavour--ingredient {
          color: $button-color-default;
          padding-left: 15px;
        }

        .product-item--data--center--flavour--additional {
          color: $styleguide-green;
          padding-left: 15px;

          .product-item--data--center--flavour--additional--value {
            font-size: 9px;
          }
        }
      }

      .product-item--data--center--complement {
        .product-item--data--center--complement--value {
          font-size: 9px;
        }
      }

      .product-item--data--center--product-observation {
        color: $styleguide-blue;
      }
    }

    .product-item--data--right {
      display: flex;
      height: inherit;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 10px;
      flex-direction: column;
      min-width: 100px;
    }
  }

  .product-item--divisor {
    display: flex;
    width: 90%;
    height: 1px;
    background-color: $border-color;
  }
}

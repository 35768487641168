@import "../../assets/scss/Variables";

.combo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .combo-item--data {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 12px;
    margin: 12px;
    line-height: 14px;
    //border: 1px solid $border-color;
    //border: 2px solid red;

    .combo-item--data--info {

      width: 100%;
      padding: 0 10px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .combo-item--data--info--left {
        border: 1px solid $border-color;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin: 0 10px 0 -10px;
        padding: 0 0 0 10px;
        height: 30px;
        width: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .combo-item--data--info--left--title {

        }

        .combo-item--data--info--left--actions {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          padding: 10px;
          svg {
            width: 20px;
            height: 20px;
          }

          .combo-item--data--info--left--actions--edit{

          }
          .combo-item--data--info--left--actions--delete{
            color: $button-color-default
          }
        }

      }
      .combo-item--data--info--right {

      }
    }
    .combo-item--data--items {
      border: 1px solid $border-color;
      border-top-right-radius:$button-border-radius;
      box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      .combo-item--data--items--observation {
        align-self: flex-start;
        color: $styleguide-blue;
        padding: 0 0 10px 10px;
      }
    }

  }

  .combo-item--divisor {
    display: flex;
    width: 90%;
    height: 1px;
    background-color: $border-color;

  }
}

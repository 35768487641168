@import "../../assets/scss/Variables";

.check-button {
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 2px solid #CFD1DC;
  border-radius: 4px;
  color: #FFF;
  align-items:center;
  justify-content: center;
  animation: buttonFade 0.3;
  cursor: pointer;
}

.selector-selected {
  background: #D00505;
  border: 2px solid #D00505;
  animation: buttonFade 0.3;
}

.selector-radio-button {
  border: 2px solid #CFD1DC;
  border-radius: 50%;
}

.selector-radio-selected {
  border: 7px solid red;
}

@keyframes buttonFade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0
  }
}
@import "../../assets/scss/Variables";

.payment-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 90%;



  .payment-info--left {
    .payment-info--left--image {
      width: 44px;
      height: 36px;
    }
  }
  .payment-info--center {
    width: 100%;
    padding-left: 10px;
    .payment-info--center--title {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
    .payment-info--center--desc {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }


  }
  .payment-info--right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    gap: 5px;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      color: $styleguide-green;
    }

    svg {
      width: 10px;
      height: 10px;
      color: $styleguide-green;
    }
  }
}
@import "../../assets/scss/Variables.scss";

.profile-change-password {
   display: flex;
   align-items: flex-end;
   justify-content: flex-end;
   background-color: rgba(0, 0, 0, 0.4);
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;

   .profile-change-password--content {
      animation: moveFromBottom 0.4s;
      width: 100%;
      height: auto;
      padding-bottom: 72px;
      gap: 20px;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-top-left-radius: $modal-border-radius;
      border-top-right-radius: $modal-border-radius;

      .profile-change-password--content--header {
         padding-top: 15px;
      }

      .profile-change-password--content--body {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 90%;
         flex-direction: column;
         gap: 10px;

         .profile-change-password--content--body--current-password {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
         }
         .profile-change-password--content--body--new-password {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
         }
         .profile-change-password--content--body--confirm-password {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
         }

         .profile-change-password--validation input {
            border: 2px solid $button-color-default;
         }
      }

      .profile-change-password--content--footer {
         display: flex;
         align-items: center;
         justify-content: center;
         width: 90%;
         flex-direction: row;
         gap: 10px;

         .profile-change-password--content--footer--cancel {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
         }
         .profile-change-password--content--footer--confirm {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
         }
      }
   }
}

@keyframes moveFromBottom {
   0% {
      transform: translateY(100%);
   }
   100% {
      transform: translateY(0);
   }
}

.modal-additional {

    .modal-header {
        .button-go-back-mobile {
            display: none;
            cursor: pointer;

        }
    }



    .modal-body {

        .collapse-item {
            overflow: auto;

            .collapse-content {
                // flex-direction: column;

                .card-additional  {
                    width: 250px;
                    height: 80px;
                    padding: 10px;

                    .card-body {
                        padding: 0;
                        align-items: center;
                        justify-content: flex-start;

                        .btn-group-vertical {
                            align-items: center;
                            margin: 0 8px 0 0;
                        }

                        .number-control {
                            .button-number {
                                min-width: 30px;
                                border-radius: 3px;
                                margin-bottom: 0;
                                color: white;
                                text-align: center;
                                padding: 4px 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .modal-footer {
        justify-content: space-between;
        align-items: center;

        .footer-button-left {
            margin: 0;
        }
    }

}
@import "../../assets/scss/Variables";

.addresses-info--item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  min-height: 60px;
  padding: 10px 0;
  border-radius: $button-border-radius;
  border: 1px solid $border-color;
  position: relative;

  label {
    margin: 0;
  }

  .addresses-info--item--left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10%;
  }

  .addresses-info--item--center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 80%;

    .addresses-info--item--center--title {
      display: flex;

      label {
        text-align: left;
        font-size: 12px;
        font-weight: 800;

      }
    }

    .addresses-info--item--center--xlgr {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      label {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
      }
    }

    .addresses-info--item--center--neighbourhood {
      display: flex;

      label {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .addresses-info--item--center--details {
      display: flex;

      label {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
      }
    }

  }

  .addresses-info--item--right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10%;
    //border : 1px solid red;
  }

}

.selected-addresses-info--item {
  background-color: lighten(#D00505, 50%);
  border: 2px solid $button-color-default;
}
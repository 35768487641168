.modal-fidelity-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #fff;
  max-width: 100%;
  max-height: 100vh;
  border-radius: 20px 20px 0 0;

  .modal-fidelity-details--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .modal-title {

      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        font-size: 14px;
      }
    }
  }


  .modal-fidelity-details--body {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;

    .modal-fidelity-details--body--content{
      display: flex;
      width: 100%;
      height: auto;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 15px;

      .modal-fidelity-details--body--content--rule-block {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        font-size: 12px;
      }

      .modal-fidelity-details--body--content--prize-block {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        font-size: 12px;
      }
      .modal-fidelity-details--body--content--retrieve-block {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        font-size: 12px;
      }
    }
  }
}
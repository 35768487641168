.section-highlights {
  padding: 20px 0 0 0;
  width: 100%;

  .section-highlights--content {
    width: 100%;
    height: 150px;
    overflow-x: auto;
    gap: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5px;
    flex-wrap: nowrap;

    .section-highlights--content--card-highlight {
      height: 100%;
      display: flex;
      cursor: pointer;
      img {
        border-radius: 5px;
        width: 70vw;
        max-width: 360px;
        height: 100%;
      }
    }
  }

  .section-highlights--content--separator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    .section-highlights--content--separator--item {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      background-color: rgba(65, 65, 65);
      // border: 1px solid red;
    }

    .separator-selected {
      background-color: #000;
    }
  }
}

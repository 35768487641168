@import "../../assets/scss/Variables.scss";

.orders-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  //border-left: 1px solid rgb(232, 232, 232);
  position: absolute;
  top: 0;
  right: 100%;
  visibility: hidden;

  .orders-info--header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid rgb(222, 222, 222);
    position: relative;
    width: 100%;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 1px;

    .orders-info--header--return {
      position: absolute;
      left: 10px;
      padding: 10px;
      cursor: pointer;
    }

    .orders-info--header--refresh {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }

  .orders-info--body {
    gap: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20px 10px !important;
    height: calc(100vh - 70px - 62px);
    overflow: auto;

    label {
      margin: 0;
    }

    .orders-info--body--pending {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .orders-info--body--pending-title {
        width: 100%;
        font-weight: 700;
        display: block;
        text-align: center;
      }

      .orders-info--body--pending--payments {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        width: 100%;
        justify-content: center;
        align-items: center;

        .orders-info--body--pending--payments--item {
          padding: 20px;
          border-radius: 10px;
          background-color: #f5f5f5;
          border: 1px solid #bfbfbf;
          max-width: 320px;
          width: 100%;

          .orders-info--body--pending--payments--item--header {
            display: flex;
            gap: 15px;
            align-items: center;

            .orders-info--body--pending--payments--item--header--pulse {
              width: 15px;
              height: 15px;
              background-color: #f48d25; /* Blue color, you can change it */
              border-radius: 50%; /* Make it circular */
              animation: pulseOrdersInfo 1s infinite alternate; /* Apply pulse effect */
            }

            h1 {
              line-height: 100%;
              margin: 0;
              padding: 0;
              font-size: 14px;
              font-weight: 700;
            }
          }

          .orders-info--body--pending--payments--item--body {
            .orders-info--body--pending--payments--item--body--progress {
              padding: 10px;
              background-color: #fff;
              border-radius: 10px;
              border: 1px solid #c0c0c0;
              margin-top: 20px;

              span {
                font-size: 14px;
                font-weight: 400;
              }

              h1 {
                font-size: 24px;
                font-weight: 700;
              }
            }

            .orders-info--body--pending--payments--item--body--total {
              h1 {
                width: 100%;
                text-align: right;
                font-size: 24px;
                font-weight: 700;
                margin-top: 10px;
                margin-bottom: 0;
                line-height: 100%;

                span {
                  font-weight: 400;
                }
              }
            }

            .orders-info--body--pending--payments--item--body--pay {
              width: 100%;
              text-align: center;
              background-color: transparent;
              border: none;
              color: #df2833;
              font-weight: 700;
              margin-top: 10px;
            }
          }
        }
      }
    }

    .orders-info--date {
      gap: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      label {
        font-weight: 600;
      }

      .orders-info--item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid $background-color-readonly-group;
        box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        label {
          font-weight: 500;
        }

        .orders-info--item--header {
          padding: 6px;
          width: 90%;
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-bottom: 1px solid $background-color-readonly-group;

          label {
            font-size: 14px;
          }

          .orders-info--item--header--top {
            width: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;

            span {
              font-weight: 600;
            }

            .orders-info--item--circle-icon {
              left: 0;
              position: absolute;
              font-size: 12px;
            }

            .orders-info--item--arrow-icon {
              right: 0;
              position: absolute;
              font-size: 12px;
              color: $styleguide-gray-high;
            }
          }

          .orders-info--item--header--bottom {
            width: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;

            label {
              font-size: 12px;
            }
          }
        }

        .orders-info--item--body {
          display: flex;
          flex-direction: column;
          gap: 2px 10px;
          padding: 8px 20px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;

          label {
            margin: 0;
            font-size: 12px;
            width: calc(50% - 5px);
          }
        }

        .orders-info--item--footer {
          padding: 8px 0;
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid $background-color-readonly-group;

          label span {
            font-weight: 600;
          }

          button {
            font-size: 12px;
            color: $text-color-default;
            background-color: transparent;
            border-radius: $button-border-radius;
            border: 1px solid $button-color-default;
          }
        }
      }
    }

    .orders-info--no-items {
      gap: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      padding: 15px;

      span:first-of-type {
        font-weight: 700;
      }

      label {
        text-align: center;
      }

      label:first-child {
        font-weight: 600;
      }

      button {
        width: 80%;
        bottom: 72px;
        position: absolute;
      }
    }
  }
}

.orders-info-active {
  animation: moveFromRight 0.3s;
  visibility: visible;
  right: 0;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes pulseOrdersInfo {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(244, 141, 37, 0.5);
  }
  70% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba(244, 141, 37, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(244, 141, 37, 0);
  }
}

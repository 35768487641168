@import "../../assets/scss/Variables";

.delivery-type-selector-take-out {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;

  font-size: 10px;

  .delivery-type-selector-take-out--header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 40px;
    align-items: center;
    flex-direction: row;
  }

  .delivery-type-selector-take-out--body {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0 38px;

    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    .delivery-type-selector-take-out--body--left {
      padding: 26px 0;
      display: flex;

      justify-content: center;
      align-items: center;

      svg {
        width: 25px;
        height: 25px;
      }
    }
    .delivery-type-selector-take-out--body--center {
      display: flex;

      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      width: 100%;
      padding-left: 20px;
      .delivery-type-selector-take-out--body--center--title {
        font-weight: 600;
      }
    }
    .delivery-type-selector-take-out--body--right {
      display: flex;

      width: 150px;
      gap: 5px;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      color: #00bf08;
      cursor: pointer;
    }
  }

  .delivery-type-selector-take-out--border {
    padding: 0 16px;
  }

  .delivery-type-selector-take-out--footer {
    text-align: left;
    width: 100%;
    margin-top: -30px;

    .delivery-type-selector-take-out--footer--title {
      font-size: 18px;
      font-weight: 700;
      width: 100%;
      color: #636363;
      text-align: center;
    }

    .delivery-type-selector-take-out--footer--buttons {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .delivery-type-selector-take-out--footer--buttons--button {
        --root-light-color: #c9c9c9;
        --root-dark-color: #626262;

        &.selected {
          --root-light-color: #c23b35;
          --root-dark-color: #c23b35;
        }

        border: 2px solid var(--root-light-color);
        padding: 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        text-align: left;
        gap: 20px;
        background-color: transparent;
        position: relative;

        .delivery-type-selector-take-out--footer--buttons--button--left {
          font-size: 36px;
          color: var(--root-dark-color);
        }

        .delivery-type-selector-take-out--footer--buttons--button--center {
          h1 {
            font-size: 18px;
            font-weight: 700;
            color: var(--root-dark-color);
            margin: 0;
          }

          p {
            margin: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@import "../../assets/scss/Variables.scss";
.benefit-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 16px;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666666;


  .benefit-item--left {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transform: rotate(-20deg);
      width: 30px;
      height: 30px;
    }

  }
  .benefit-item--center {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    ;

    .benefit-item--center--title {
      font-weight: 600;
    }
    .benefit-item--center--desc {

    }



  }
  .benefit-item--right {
    display: flex;
    align-items: center;
 

    span {
      font-weight: 700;
      color:  $styleguide-green;
      cursor: pointer;
    }

  }
}
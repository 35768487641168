@import "../../assets/scss/Variables.scss";

.delivery-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  cursor: pointer;

  .delivery-info--left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    // border: 1px solid red;

    svg {
      height: 30px;
    }
  }

  .delivery-info--center {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    font-size: 12px;

    .delivery-info--center--upper {
      font-weight: 600;
    }
    .delivery-info--center--bottom {
      font-weight: 400;
      display: flex;
      flex-direction: column;

      label {
        margin: 0;
        padding: 0;
        cursor: pointer;
      }
    }
  }

  .delivery-info--right {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

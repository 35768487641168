@import "../../assets/scss/Variables.scss";

.order-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 100%;
  visibility: hidden;

  .order-details--header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid rgb(222, 222, 222);
    position: relative;
    width: 100%;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 1px;

    .order-details--header--return {
      position: absolute;
      left: 10px;
      padding: 10px;
      cursor: pointer;
    }

    .order-details--header--refresh {
      position: absolute;
      right: 10px;
    }
  }

  .order-details--body {
    gap: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: calc(100% - 72px);
    padding-bottom: 72px;

    .order-details--body--top {
      gap: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;

      label {
        margin: 0;
      }

      .order-details--body--top--header {
        gap: 10px;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        label {
          font-size: 18px;
          font-weight: 600;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 5px;
        }

        .order-details--body--top--header--right {
          div {
            gap: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            label:first-child {
              font-size: 18px;
              font-weight: 600;
            }

            label:last-child {
              font-size: 14px;
              font-weight: 400;
            }
          }

          label {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      .order-details--body--top--body {
        label {
          font-size: 12px;
        }
      }

      .order-details--body--top--footer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .order-details--body--top--footer-status {
          gap: 10px;
          width: 93%;
          display: flex;
          padding: 6px 10px;
          border-radius: 5px;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          background-color: #F3F3F3;

          label {
            font-size: 12px;
            width: 80%;

          }
          .reason-label{
            //text-align: center;
          }

          .order-details--body--top--footer-status-circle {
            background-color: rgb(29, 195, 41);
            height: 20px;
            width: 20px;
            border-radius: 50%;

            box-shadow: 0 0 0 0 rgba(29, 195, 41, 1);
            transform: scale(.5);
            animation: pulse 2s infinite;
          }
        }

        .order-details--body--top--footer-follow {
          width: 93%;
          margin-top: 10px;
        }

      }


      .canceled {
        .order-details--body--top--footer-status {
          .order-details--body--top--footer-status-circle {
            background-color: $theme-1-color-2;
            animation: none;
          }
        }
      }

      .final {
        .order-details--body--top--footer-status {
          .order-details--body--top--footer-status-circle {
            animation: none;
            transform: scale(0.9);
          }
        }

        .order-details--body--top--footer-follow {
          display: none;
        }
      }

      .reason{
        padding: 6px 10px 6px 0px;
      }
    }

    .order-details--body--center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 10px;
      gap: 15px;
      font-size: 14px;
      overflow-y: auto;
      height: 100%;

      .order-details--body--center--products {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .order-details--body--center--products--title {
          font-weight: 700;
        }

        .order-details--body--center--products--item {
          span {
            font-weight: 700;
          }
        }
      }

      .order-details--body--center--beverages {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .order-details--body--center--beverages--title {
          font-weight: 700;
        }

        .order-details--body--center--beverages--item {
          span {
            font-weight: 700;
          }
        }
      }

      .order-details--body--center--benefits {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        span {
          font-weight: 700;
        }

        .order-details--body--center--benefits--item {
          span {
            font-weight: 700;
          }
        }
      }
    }

    .order-details--body--bottom {
      gap: 10px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      box-shadow: 0 -6px 16px 1px rgba(0, 0, 0, 0.2);

      .order-details--body--bottom--totals {
        width: 90%;
        display: flex;
        flex-direction: column;

        div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        span {
          font-size: 18px;
          font-weight: 800;
        }
      }

      .order-details--body--bottom--address {
        width: 90%;
        display: flex;
        margin-bottom: 10px;
        flex-direction: column;

        label {
          margin: 0;
        }

        .order-details--body--address--top {
          label {
            font-size: 12px;
            color: $button-color-default;
          }
        }

        .order-details--body--address--bottom {
          display: flex;
          flex-direction: column;
          font-size: 14px;

          .order-details--body--address--bottom--title {
            margin-top: 6px;
            font-size: 14px;
            font-weight: 600;
          }

          .order-details--body--address--bottom--street {
            font-size: 14px;
          }

          .order-details--body--address--bottom--city {
            font-size: 14px;
          }

          .order-details--body--address--bottom--extra {
            font-size: 12px;
            padding-left: 6px;
          }
        }
      }
    }
  }

  .order-details--schedule--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    .order-details--schedule--body--top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid $border-color;
      font-size: 16px;
      line-height: 16px;


      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;

        &:first-child {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .order-details--schedule--body--center {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid $border-color;

      padding: 20px 10px;
      line-height: 14px;
      font-size: 12px;


      .order-details--schedule--body--center--left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 20%;
        gap: 10px;

        svg {
          height: 30px;
        }
      }

      .order-details--schedule--body--center--center {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        padding-left: 10px;
        font-size: 12px;

        .order-details--schedule--body--center--center--upper {

          font-weight: 600;

        }

        .order-details--schedule--body--center--center--bottom {
          font-weight: 400;

          p {
            margin: 0;
            padding: 0;
          }
        }
      }

      .order-details--schedule--body--center--right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 200px;
        color: $styleguide-green;
        gap: 5px;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;

      }
    }

    .order-details--schedule--body--bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px 10px;
      border-bottom: 1px solid $border-color;

      .order-details--schedule--body--bottom--left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 20%;
        svg {
          height: 30px;
        }
      }

      .order-details--schedule--body--bottom--center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

        span {
          font-size: 20px;
          font-weight: 700;
        }
      }

      .order-details--schedule--body--bottom--right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 20%;
        svg {
          height: 30px;
        }
      }
    }
  }
}

.order-details-active {
  animation: moveFromRight 0.3s;
  visibility: visible;
  right: 0;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.90);
    box-shadow: 0 0 0 0 rgba(29, 195, 41, 0.5);
  }

  70% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.90);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

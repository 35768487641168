@import "../../assets/scss/Variables.scss";

.radio-button {
   display: flex;
   width: 30px;
   height: 30px;
   border: 2px solid #cfd1dc;
   border-radius: 50%;
   justify-content: center;
   align-items: center;
   margin: 0;
   padding: 0;

   .radio-button-selector {
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      display: flex;
      width: 52%;
      height: 52%;
      background-color: #fff;
      border-radius: 50%;
   }
}

.radio-active {
   background-color: $button-color-default;
   border: 2px solid #fff;
}

@import "../../assets/scss/Variables";

.payment-type-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;

  .payment-type-selector--header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dadada;

    .payment-type-selector--header--left {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background: transparent;
      border: none;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
    }

    .payment-type-selector--header--right {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background: transparent;
      border: none;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
    }

    .payment-type-selected {
      font-weight: 800;
      border-bottom: 1px solid $button-color-default;
    }
  }

  .payment-type-selector--body {
    display: flex;
    width: 90%;
    flex-direction: column;
    overflow-y: auto;
    max-height: 70vh;

    &.left {

      animation: fromLeft .5s ease forwards;
    }

    &.right {
      animation: fromRight .5s ease forwards;
    }
  }

  .payment-type-selector--footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
    width: 90%;

  }
}

@keyframes fromRight {
  from {
    position: relative;
    right: -100vw;
  }
  to {
    position: relative;
    right: 0;
  }
}

@keyframes fromLeft {
  from {
    position: relative;
    left: -100vw;
  }
  to {
    position: relative;
    left: 0;
  }
}
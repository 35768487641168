.modal-map-address {
  display: flex;
  overflow: hidden;

  .modal-map-address--header {
    padding: 0;

    .modal-map-address--header-label {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      background-color: #d00505ff;
      width: 100vw;

      h2 {
        font-size: 14px;
        max-width: 80%;
        margin: 0;
        color: #fff;
        font-weight: 700;
      }
    }
  }

  .modal-map-address--body {
    padding: 0;
    width: 100%;
    height: calc(100vh - 75px);
    position: relative;

    .modal-map-address--body-marker-icon {
      img {
        width: 35px;
        height: 45px;
      }
    }

    .gmnoprint {
      .gm-style-mtc,
      .gm-svpc {
        display: none;
      }
    }

    .gm-style-cc {
      display: none;
    }

    .gm-fullscreen-control {
      display: none;
    }
  }

  .modal-map-address--footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .modal-map-address--footer {
      margin-bottom: 10px;
    }
  }
}

@import "../../assets/scss/Variables.scss";

.check-selector-button {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 25px;
   height: 25px;

   background-color: transparent;
   border: 1px solid #cfd1dc;
   color: white;
   color: white;
}

.check-selector-active {
   background-color: $button-color-default;
   border: 1px solid $button-color-default;
   color: white;

   &:hover {
      background-color: #dc3545;
      border: 1px solid #dc3545;
      color: white;
   }
}

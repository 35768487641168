@import "../../assets/scss/Variables.scss";

.quantity-control {
  display: flex;
  flex-direction: row;
  width: 70px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid $button-color-default;
  border-radius: $button-border-radius;
  color: $button-color-default;
  //box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
  opacity: 0.8;


  .quantity-control--left {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;


  }

  .quantity-control--center {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    opacity: 0.8;
    justify-content: center;
    color: $button-color-default;
  }

  .quantity-control--right {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
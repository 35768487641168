.fidelities-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  //border-left: 1px solid rgb(232, 232, 232);
  position: absolute;
  top: 0;
  right: 100%;
  visibility: hidden;

  .fidelities-info--header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid rgb(222, 222, 222);
    position: relative;
    width: 100%;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 1px;

    svg {
      position: absolute;
      left: 10px;
      cursor: pointer;

      padding:10px;
    }
  }

  .fidelities-info--body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    padding: 5px;
    gap: 3px;

    .fidelities-info--body--item {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      flex-direction: column;
      padding: 3px;
      border: 1px solid #c1c1c1;
      border-radius: 3px;

      .fidelities-info--body--item--top {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        flex-direction: column;
        width: 100%;
        padding: 5px;
        font-size: 12px;
        strong {
          font-size: 14px;
        }
      }
      .fidelities-info--body--item--bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        //border: 1px solid red;
        padding: 5px;

        .fidelities-info--body--item--bottom--progress-bar {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 50%;
          height: 10px;
          background-color: #c1c1c1;
          border-radius: 3px;
          padding: 0 3px;

          .fidelities-info--body--item--bottom--progress-bar--progress {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 5px;
            border-radius: 3px;
          }
        }
        .fidelities-info--body--item--bottom--value {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 5px;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }

  .fidelities-info--need-update {
    position: absolute;
    bottom: 62px;
    width: 100%;
    left: 0;
    //border: 1px solid red;

    button {
      height: 62px;
      width: 100%;
      border: none;
      background-color: #c23b35;
      color: #fff;
    }
  }
}

.fidelities-info-active {
  animation: moveFromRight 0.3s;
  visibility: visible;
  right: 0;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

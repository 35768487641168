@import "../../../assets/scss/Variables";

.modal-company-contacts {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  .modal-company-contacts--content {
    animation: moveFromBottom 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    border-top-left-radius: $modal-border-radius;
    border-top-right-radius: $modal-border-radius;
    padding: 20px 0 0 0;
    height: auto;
    gap: 10px;


    .modal-company-contacts--content--header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .modal-company-contacts--content--body {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .modal-company-contacts--content--body--upper {
        font-size: 14px;
        font-weight: 500;
      }
      .modal-company-contacts--content--body--bottom {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        //border: 1px solid red;
        padding: 10px 0 0 0;
        gap: 20px;

        .modal-company-contacts--content--body--bottom--contact {
          text-decoration: none;
          color: #000;
          svg {
            width: 40px;
            height: 40px;
          }
        }

      }
    }

    .modal-company-contacts--content--footer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 10px 10px 10px;
    }

  }
}
@import "../../assets/scss/Variables.scss";

.modal-signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 100vw;
  //height: 100vh;
  z-index: 102;


  .modal-signin--header {
    padding: 20px 0 0 0;
    border: none;

    .modal-title {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      label{
        font-size: 14px;
      }

      .modal-signin--content--header--img-logo {
        width: 60%;
        max-width: 200px;
        border-radius: 50%;
        margin: 10px 0;

      }

      .modal-signin--content--header--input {
        display: flex;
        flex-direction: row;
        width: 100%;

        .modal-signin--content--header--input--ddd {
          height: 38px;
          width: 58px;
          margin: 0;
          padding: 0;
          border-radius: 0.375rem;
          border: 1px solid #ced4da;
          font-family: inherit;
          font-weight: 400;
          line-height: 1.5;
          background-color: #fff;
          background-clip: padding-box;
          margin-right: 5px;
        }

        input {
          font-size: 14px;
          text-align: center;
          margin-bottom: 10px;

          &:focus {
            background-color: #ffffff;
            border-color: #c2c2c2;
            outline: 0;
            box-shadow: 2px 2px 2px 0.2px rgba(50, 33, 35, 0.2);
          }
        }
      }
    }
  }

  .modal-signin--body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 10px 20px;

    p {
      font-size: 16px;
      margin: 0;
      text-align: center;
    }

    input {
      text-align: center;
    }

    .modal-signin--body--exist-button {
      padding: 0;
      border: none;
      background-color: transparent;
      width: 100%;
      font-size: 14px;
      margin-top: 10px;
      color: #dc3545;
      text-decoration: underline;
      transition: 0.3s all ease;

      &:hover {
        color: #b92030;
      }
    }

    .modal-signin--content--body--exist {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // border: 1px solid red;

      p:nth-child(2) {
        margin-bottom: 10px;
      }

      .modal-signin--content--body--exist--password {
        margin-bottom: 10px;
      }

      // .modal-signin--content--body--exist {
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;
      //   justify-content: center;
      //   width: 100%;

      //   p {
      //     margin-bottom: 5px;
      //   }

      //   input {
      //     margin-bottom: 10px;
      //     font-size: 16px;
      //   }
      // }
    }

    .modal-signin--content--body--non-exist {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        margin-bottom: 5px;
      }

      input {
        margin-bottom: 10px;
        font-size: 16px;
      }

      .modal-signin--content--body--content--non-exist--phone {
        // border: 1px solid red;
        width: 100%;
        display: flex;
        flex-direction: row;

        .modal-signin--content--body--content--non-exist--phone--ddd {
          height: 38px;
          width: 58px;
          margin: 0;
          padding: 0;
          border-radius: 0.375rem;
          border: 1px solid #ced4da;
          font-family: inherit;
          font-weight: 400;
          line-height: 1.5;
          background-color: #fff;
          background-clip: padding-box;
          margin-right: 5px;
        }

        .modal-signin--content--body--content--non-exist--phone--number {
          text-align: center;
        }
      }

      .modal-signin--content--body--non-exist--whatsapp-box {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
  }

  .modal-signin--content--footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;

    .modal-signin--content--footer--button {
      gap: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  .modal-signin--content--close-button {
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  //}
}

.invalid-register {
  border: 2px solid red;
}

@keyframes moveFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@import "../assets/scss/Variables.scss";

.section-footer {
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 62px;
  background-color: #fff;
  bottom: 0;

  margin: 0 !important;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: space-around;

  box-shadow: 0 0 10px 8px rgba(183, 183, 183, 0.2);

  .section-footer--menu-item {
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      padding: 10px;
    }

  }

  .selected-tab {
    border-bottom: 2px solid black;
  }

}

@import "../../assets/scss/Variables";

.addresses-info--options {

  //height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  align-items: center;
  line-height: 16px;
  width: 100%;

    .addresses-info--options--content--header {

      .modal-title {
        gap: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        span {
          font-size: 16px;
          font-weight: 700;
        }

        span:last-child {
          font-size: 12px;
          font-weight: 400;
        }
      }

    }
    .addresses-info--options--content--body {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      gap: 10px;


      button {
        font-size: $button-font-size;
        width: 130px;
        height: 40px;
        border-radius: $button-border-radius;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }

      .addresses-info--options--content--body--edit {
        color: #D00505;
        background-color: #FFF;
        border: 1px solid #D00505;
      }

      .addresses-info--options--content--body--cancel {
        color: #FFF;
        background-color: #D00505;
      }

    }
    .addresses-info--options--content--footer {
      span {
        font-size: 12px;
        color: $styleguide-red;
      }

  }
}




@keyframes moveFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
@import "../../../assets/scss/Variables";

.modal-order-process  {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100vh;
  padding: 0 15px ;

  .modal-content {
    border-radius: 15px !important;
    max-width: 100%;

  }
    .modal-order-process--body {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px 20px 20px 20px;

      .modal-order-process--body--content {
      //  border: 1px solid red;

        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0 10px 0;
        label {
          text-align: center;
        }


       // border: 1px solid red;

      .modal-order-process--body--content--buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;

        .modal-order-process--body--content--button{
          display: flex;
          margin: 4px;
          max-width: 50%;
          font-size: 14px;
          padding-bottom: 2px;
        }

      }

      }}




  .modal-order-process--footer {
    }


}


@keyframes moveFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

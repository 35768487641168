@import "../../assets/scss/Variables.scss";

.number-control {
  width: 70px;
  height: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 0.5px solid #e4e4e4;
  border-radius: 6px;

  // .number-control--header {
  //   width: 100%;
  // }

  .number-control--body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;

    .number-control--body--button-icon {
      cursor: pointer;
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
      color: $button-color-default;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
      }

      .disabled {
        color: #e4e4e4;
      }
    }

    .number-control--body--button-number {
      margin: 0;
      // padding: 4px 10px;
      // border-radius: 3px;
      pointer-events: none;

      font-family: "Lato";
      font-style: normal;
      font-weight: 500;
      font-size: 11.0398px;
      line-height: 13px;
      text-align: center;
      color: #000;
    }

    &:hover {
      color: darken(#6c7177, 5%);
    }

    &:focus {
      box-shadow: unset !important;
    }

    &:active {
      background-color: transparent !important;
    }
  }

  .vertical {
    flex-direction: column;
  }

  .horizontal {
    flex-direction: row;
  }
}

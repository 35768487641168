@import "../../../assets/scss/Variables";

//.modal-dialog{
//  max-width: 100%;
//  max-height: 100%;
//}

.modal-initial-address {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  //width: 100vw;
  max-width: 100%;
  max-height: 100vh;

  .modal-initial-address--header {
    padding: 0;
    border: none;

    .modal-title {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
      width: 100%;
      padding: 28px 40px 10px 40px;

      font-weight: 600;
      font-size: 16px;
      color: #000;

      .modal-initial-address--header--delivery {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 0 0 20px 0;
        width: 100%;
        cursor: pointer;
      }

      .modal-initial-address--header--take-out {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 0 0 20px 0;
        text-align: center;
        width: 100%;
        cursor: pointer;
      }

      .modality-selected {
        font-weight: 800;
        border-bottom: 2px solid black;
      }
    }
  }

  .modal-initial-address--body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 20px 0 0 0;
    background-color: #fff;

    border-top-left-radius: $modal-border-radius;
    border-top-right-radius: $modal-border-radius;

    .modal-initial-address--body--options {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 80%;
      gap: 10px;

      span {
        font-size: 14px;
        text-align: center;
      }

      .modal-initial-address--body--options--button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        gap: 15px;
        flex-direction: row;
        border: 1px solid #666666;
        border-radius: $button-border-radius;
        cursor: pointer;
        margin-top: 10px;
        width: 100%;

        label {
          cursor: pointer;
        }
        svg {
          width: 25px;
          height: 25px;
        }

        .modal-initial-address--body--options--button--description {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          flex-direction: column;
          margin: 0;
          font-size: 12px;
          gap: 0;

          .modal-initial-address--body--options--button--description--title {
            margin: 0;
            color: $button-color-default;
            font-weight: 700;
          }

          .modal-initial-address--body--options--button--description--subtitle {
            margin: 0;
            text-align: left;
          }

          .modal-initial-address--body--options--button--description--input {
            border: none;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 5px;
            color: #000;
          }
        }
      }
      .selected-input {
        border: 2px solid red;
      }
      // .gmap-container {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   width: 100%;

      //   div:last-child {
      //     width: 100%;
      //     justify-content: center;
      //     align-items: center;
      //     display: flex;
      //     flex-direction: column;
      //     padding: 0 0 10px 0;

      //     .address-suggestions {
      //       display: flex;
      //       align-items: center;
      //       justify-content: center;
      //       width: 100%;
      //       margin-top: 4px;

      //       .address-suggestion {
      //         display: flex;
      //         align-items: center;
      //         justify-content: center;
      //         width: 100%;
      //         flex-direction: row;
      //         border-bottom: 1px solid $border-color;
      //         cursor: pointer;
      //         label {
      //           cursor: pointer;
      //         }
      //         svg {
      //           width: 25px;
      //           height: 25px;
      //           padding: 0 10px;
      //         }

      //         .description-suggestion {
      //           display: flex;
      //           justify-content: center;
      //           align-items: flex-start;
      //           width: 100%;
      //           flex-direction: column;
      //           margin: 0;
      //           font-size: 12px;
      //           gap: 0;

      //           .description-suggestion--title {
      //             margin: 0;
      //           }

      //           .description-suggestion--subtitle {
      //             margin: 0;
      //           }
      //         }
      //       }

      //       .address-selected {
      //         border: 1px solid $button-color-default;
      //         // background-color: #dfffe0;
      //         border-radius: $button-border-radius;
      //         padding: 10px 0;

      //         .icon-marker,
      //         .icon-check {
      //           color: $button-color-default;
      //         }
      //       }
      //     }

      //     .input-address-suggestions {
      //       padding: 10px;
      //       font-size: 12px;
      //     }
      //   }
      // }

      .modal-initial-address--body--options--addresses {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-height: 40vh;

        .modal-initial-address--body--options--addresses--divider {
          width: 100%;
          height: 2px;
          background-color: $border-color;
          margin-bottom: 10px;
        }

        .modal-initial-address--body--options--addresses--items {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          border-radius: 9px 9px;
          overflow-y: auto;
          box-shadow: inset -5px -30px 25px -30px rgba(0, 0, 0, 0.2);

          .addresses-info--item {
            width: 100%;
            cursor: pointer;
            label {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .modal-initial-address--footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    gap: 10px;
    border: none;
    flex-wrap: nowrap;
    margin: 0 auto;
    .modal-initial-address--footer--cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      button {
        width: 100%;
      }
    }
    .button-close {
      margin: 0 20px;
      button {
        width: 100%;
      }
    }

    .modal-initial-address--footer--confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}

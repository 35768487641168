@import "../../assets/scss/Variables.scss";

.observation-input {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  //border: 1px solid red;

  .observation-input--top {
    //border: 1px solid red;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .observation-input--top--title {
      font-size: 14px;
      font-weight: 700;
    }

    .observation-input--top--size {
      font-size: 10px;
      font-weight: 400;
    }

  }

  .observation-input--bottom {
    //border: 1px solid red;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    input {
      width: 100%;
      height: 50px;
      padding: 0 10px;
      border: 1px solid $border-color;
      border-radius: $button-border-radius;
    }
  }
}
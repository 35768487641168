@import "../../assets/scss/Variables";

.benefit-finder {

  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  width: 100vw;
  max-width: 100vw;
  height: auto;
  z-index: 102;
  background-color: #FFF;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  .benefit-finder--header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid rgb(222, 222, 222);
    position: relative;
    width: 100%;

    h5 {
      font-size: 16px;
    }

    svg {
      position: absolute;
      left: 10px;
      padding: 2px 12px;
      cursor: pointer;
    }
  }

  .benefit-finder--body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;

    .benefit-finder--body--top {
      display: flex;
      padding: 0 10px;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      width: 100%;

      gap: 10px;


      input {
        height: 40px;
        font-size: 12px;
        color: inherit;
        border-radius: 10px;
        border: 1px solid $border-color;
        width: 100%;
        padding-left: 10px;
      }

      .benefit-finder--body--top--button {
        width: 100px;
      }
    }

    .benefit-finder--body--bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: column;
      padding: 0 10px;

      .benefit-finder--body--bottom--item {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
        flex-direction: row;
        min-height: 50px;
        border: 1px solid #c1c1c1;
        border-radius: 5px;

        .benefit-finder--body--bottom--item--left {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 49px;

          svg {
            width: 20px;
            height: 20px;
          }
        }


        .benefit-finder--body--bottom--item--right {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 49px;

          span {
            font-size: 12px;
            text-align: center;
          }


        }

        .benefit-finder--body--bottom--item--right--remove {
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 1000;

          svg {
            width: 25px;
            height: 25px;
          }
        }

      }

      .promotion-selected {
        border: 2px solid #3bbd0d;
        color: #3bbd0d;
      }
    }
  }
}

@keyframes moveFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@import "../assets/scss/Variables";


#zoom-buttons {
  position: absolute;
  bottom: 200px;
  right: 15px;
  z-index: 1;
  display: flex;
  flex-direction: column;

  button {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 28px;
    cursor: pointer;
  }
}



.marker-image {
  border-radius: 30px !important;
}

.modal-map-address--body-marker-icon {
  margin-left: 20px;
  margin-bottom: 60px;
}

mapbox-search-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // height: 46px;
  gap: 15px;
  flex-direction: row;
  border: 1px solid #666666;
  border-radius: $button-border-radius;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  svg {
    width: 25px;
    height: 25px;
  }
  input {
    // width: 100% ;
    height: 46px;
    // margin: 0 !important;
    // padding: 0 !important;
  }
}

.mapscf--placemap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 46px;
  gap: 10px;
  padding: 0 20px;
  border: 1px solid #666666;
  border-radius: $button-border-radius;

  .placemap-place {
    width: 25px;
    height: 25px;
  }

  .mapscf--placemap--input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    color: #000;
    border: none;

    &:focus {
      outline: none;
    }
  }
  .selected-input {
    border: 1px solid red;
  }

  .placemap-loading {
    width: 15px;
    height: 15px;
  }
}

.mapscf--suggestions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 3px;
  .mapscf--suggestions--item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 45px;
    .mapscf--suggestions--item--left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;

      svg {
        color: #666;
        width: 25px;
        height: 25px;
      }
    }

    .mapscf--suggestions--item--right {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      width: 80%;
      .mapscf--suggestions--item--right--upper {
        display: flex;
        justify-content: flex-start;
        width: 90%;
        align-items: center;

        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .mapscf--suggestions--item--right--bottom {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
      }
    }
  }
}

mapbox-search-box {
  border: none !important;
}
.modal-flavours {
  display: flex;
  justify-content: flex-end;
  min-width: 100%;
  align-items: flex-start;
  //overflow: hidden;
  -webkit-overflow-scrolling: touch;
 .modal-content {
   border-radius: unset !important;
 }
  .modal-header {
    display: flex;
    width: 100%;

    .modal-title {
      display: flex;
      width: 50%;
      min-width: 250px;
      flex-direction: column;

      .modal-flavours--header--search-input {
        width: 100%;

        input {
          font-size: 14px;
        }
      }
    }
  }

  .modal-body {
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    overflow-y: auto !important;
    align-content: baseline;

    .card-flavours {
      display: flex;
      border-radius: 6px;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 10px;

      .card-img {
        max-width: 120px;
        object-fit: fill;
        border-radius: 0 5px 5px 0;
        background-color: #d6d6d6;
      }

      .card-body {
        flex: 1;
        display: flex;
        padding: 16px 12px;
        flex-direction: column;
        justify-content: space-around;

        p {
          margin: 0;
        }

        .card-title {
          margin: 0;
          font-size: 16px;
          font-weight: 600;
        }

        .card-description {
          max-height: 80px;
          overflow-y: auto;
          font-size: 14px;
          font-weight: 200;
          margin: 10px 0;
        }

        .card-price {
          font-size: 16px;
          font-weight: 800;
          font-family: "Rubik", serif !important;
        }

        .card-price-exceeded {
          font-size: 12px;
          color: #23b441;
        }
      }
    }

    .item {
      display: flex;
      cursor: pointer;
      flex-direction: row;
      padding: 10px 0 10px 0;
      border-radius: 3px;
      border-bottom: 1px solid darken(#ffffff, 10%);

      &:hover {
        background-color: darken(#ffffff, 12%);
      }

      .image {
        img {
          padding-left: 10px;
          width: 80px;
        }
      }

      .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 10px 0 20px;

        label {
          cursor: pointer;
        }

        .title {
          font-size: 16px;
          font-weight: 600;
        }

        .description {
          font-size: 12px;
          font-weight: 400;
        }

        .price {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .loading-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 25px 0;
    }

    .hide-loading-more {
      display: none;
    }

    .hide-no-more-flavours {
      display: none;
    }
  }
}

.modal-client-address-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 100vh;
  padding: 0 15px;

  .modal-content {
    border-radius: 15px !important;
    max-width: 100%;

    .modal-client-address-not-found--body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h1 {
        margin: 4px;
        max-width: 80%;
        font-size: 14px;
        padding-bottom:10px;
        text-align: center;
      }

      .modal-client-address-not-found--body-buttons {
        width: 100%;
        display: flex;
        gap: 10px;
      }
    }
  }
}
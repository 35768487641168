.section-beverages {
  padding: 20px 0 0 0;

  .beverage-title {
    padding-left: 20px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      margin: 0;
      color: #000000;
      font-size: 22px;
      font-weight: 600;
    }

    span {
      font-size: 14px;
    }
  }

  .beverage-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .card-beverage,
    .card-category {
      margin-right: 10px;
      margin-top: -1px;
      margin-bottom: 10px;
      border-radius: unset;
      border: 1px solid transparent;
      border-top: 1px solid rgb(232, 232, 232);
      border-bottom: 1px solid rgb(232, 232, 232);
      display: flex;
      cursor: pointer;
      flex-wrap: nowrap;
      gap: 10px;
      padding: 10px;
      flex-direction: row;

      .card-img {
        width: 150px;
        height: 140px;

        object-fit: cover;
        border-radius: 5px;
        background-color: #e5e5e5;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        // padding: 16px 12px;

        p {
          margin: 0;
        }

        .card-category-title{
          margin: 0;
          font-size: 16px !important;
          font-weight: 800;
          word-wrap: break-word;
        }

        .card-title {
          margin: 0;
          font-size: 14px !important;
          font-weight: 500;
          word-wrap: break-word;
        }

        .card-description {
          max-height: 80px;
          overflow-y: auto;
          font-size: 14px;
          font-weight: 300;
          margin: 10px 0;
          font-family: "Lato", sans-serif !important;
        }

        .card-price {
          font-size: 14px;
          font-weight: 500;
          font-family: "Rubik", sans-serif !important;
        }
      }
      .card-preview-image {
        position: relative;

        .flag-image {
          position: absolute;
          right: 0;
          top: 10px;
          z-index: 2;

          .flag-image--body {
            max-width: 150px;
            height: 20px;
            position: relative;
            box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);

            div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              label {
                margin: 0;
                padding: 0 4px;
                font-size: 10px;
                color: #ffffff;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            &:after {
              content: "";
              position: absolute;
              left: -8px;
              bottom: 0;
              width: 0;
              height: 0;
              border-right-style: solid;
              border-right-width: 8px;
              border-right-color: inherit;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
            }
          }
        }

        .card-img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 5px;
          background-color: #e5e5e5;
        }
      }
    }
  }
}

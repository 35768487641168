@import "../../assets/scss/Variables";

.schedule-date-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  .schedule-date-selector--top {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: auto;
    padding: 10px 0;

    input {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      padding: 0 10px;
      background-color: transparent;
      border: 1px solid rgb(222, 222, 222);
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -webkit-appearance: none;
      text-align: center;
    }
  }

  .schedule-date-selector--body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 10px 0;

    .schedule-date-selector--body--loading {
      gap: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

    .schedule-date-selector--body--week-day {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      gap: 10px;
    }

    .schedule-date-selector--body--week-day--working-times {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      padding: 10px 20px;
      height: auto;
      gap: 10px;
      //border: 1px solid red;

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }

      .schedule-date-selector--body--week-day--working-times--items {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: auto;
        gap: 10px;
        //border: 1px solid red;
      }
    }
  }
}

@import "../assets/scss/Variables.scss";

.menu-options {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  background-color: #f4f5f7;
  transition: background 0.3s;
  height: 60px;
  padding-left: 5px;
  width: 90%;


  &::-webkit-scrollbar {
    height: 0;
  }

  .menu-options-items {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    padding-left: 80px;

    label {
      margin: 0;
      cursor: pointer;
      padding: 10px 20px;
      font-size: 18px;
      font-weight: 600;
      white-space: nowrap;
      transition: background 0.3s, color 0.3s;
      border-bottom: 2px solid transparent;

      // &:hover {
      //   color: $button-color-default;
      // }
    }
  }
}

.menu-options-fixed {
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  position: fixed;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  background-color: #f7f7f7;
  overflow-x: auto;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.2);
  animation: menu-fixed-animation 0.4s ease-in-out;

  &::-webkit-scrollbar {
    height: 0;
  }

  .menu-options-items {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    padding-left: 90px;

    label {
      margin: 0;
      cursor: pointer;
      padding: 10px 20px;
      font-size: 18px;
      font-weight: 600;
      white-space: nowrap;
      transition: background 0.3s, color 0.3s;
      border-bottom: 2px solid transparent;
    }

    .active {
      border-bottom: 2px solid $button-color-default;

      padding: 0;
    }
  }
}

@mixin animation-mixin($name) {
  @keyframes #{$name} {
    0% {
      margin-top: -100px;
      opacity: 0;
    }
    100% {
      margin-top: 0;
      opacity: 1;
    }
  }
}

@include animation-mixin(menu-fixed-animation);

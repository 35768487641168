.section-combos {
  padding: 20px 0 0 0;
  width: 100%;
  pointer-events: auto;
  -webkit-user-select: none; /* Evitar seleção de texto em iOS */
  .content-combos {
    .combos-title {
      padding-left: 20px;
      padding-top: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      pointer-events: auto;
      -webkit-user-select: none; /* Evitar seleção de texto em iOS */
      // padding-top: 20px;

      label {
        margin: 0;
        color: #000000;
        font-size: 22px;
        font-weight: 600;
      }

      span {
        font-size: 14px;
      }
    }

    .combos-content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 10px;

      .card-combo {
        display: flex;
        margin-top: -1px;
        margin-bottom: 10px;
        border-radius: unset;
        border: 1px solid transparent;
        border-top: 1px solid rgb(232, 232, 232);
        border-bottom: 1px solid rgb(232, 232, 232);
        cursor: pointer;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 10px;
        pointer-events: auto;
        -webkit-user-select: none; /* Evitar seleção de texto em iOS */

        .card-img {
          object-fit: cover;
          border-radius: 0 5px 5px 0;
          background-color: #e5e5e5;
        }

        .card-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0;

          p {
            margin: 0;
          }

          .card-title {
            margin: 0;
            font-size: 16px !important;
            font-weight: 500;
            word-wrap: break-word;
          }

          .card-description {
            max-height: 80px;
            overflow-y: auto;
            font-size: 14px;
            font-weight: 300;
            margin: 10px 0;
            font-family: "Lato", sans-serif !important;
          }

          .card-price {
            font-size: 14px;
            font-weight: 500;
            font-family: "Rubik", serif !important;
          }
        }

        .card-preview-image {
          position: relative;

          .flag-image {
            position: absolute;
            right: 0;
            top: 10px;
            z-index: 2;

            .flag-image--body {
              max-width: 150px;
              height: 20px;
              position: relative;
              box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);

              div {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                label {
                  margin: 0;
                  padding: 0 4px;
                  font-size: 10px;
                  color: #ffffff;
                  text-align: center;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              &:after {
                content: "";
                position: absolute;
                left: -8px;
                bottom: 0;
                width: 0;
                height: 0;
                border-right-style: solid;
                border-right-width: 8px;
                border-right-color: inherit;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
              }
            }
          }

          .card-img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: 5px;
            background-color: #e5e5e5;
          }
        }
      }
    }
  }
}

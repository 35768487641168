@import "../../../assets/scss/Variables.scss";

.modal-order-schedule {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;

  .modal-order-schedule--header {
    width: 100%;
    height: 60px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid rgb(222, 222, 222);

    h5 {
      font-size: 16px;
    }

    svg {
      left: 10px;
      cursor: pointer;
      position: absolute;
      padding: 2px 12px;
    }
  }

  .modal-order-schedule--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;

    .modal-order-schedule--body--date-selector {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      // border: 1px solid green;
    }

    .modal-order-schedule--body--delivery-info {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px 20px;
      border-top: 1px solid $border-color;
    }

    .modal-order-schedule--body--payment-info {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px;
      border-top: 1px solid $border-color;
    }
  }

  .modal-order-schedule--footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    gap: 10px;
    border: none;
    flex-wrap: nowrap;
    margin: 0 auto;

    .modal-order-schedule--footer--cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      button {
        width: 100%
      }
    }

    .modal-order-schedule--footer--confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

    }
  }

}

@keyframes moveFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}


@import "../assets/scss/Variables.scss";

.main-options {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  margin-top: 10px;
  margin-bottom: 10px;
  animation: moveFromBottom 0.3s;

  .main-option-company {
    color: #ffffff;
    background-color: $button-color-default;
    border-radius: 3px;
    padding: 3px 6px;
    font-size: 12px;
    margin: 10px 0 0 0;
  }

  .main-option-about {
    // border: 1px solid red;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    left: 0;
    padding-left: calc(100vw - 90vw);
    // padding-left: 35px;
    position: absolute;
    top: 0;

    label {
      font-size: 12px;
      font-weight: 700;
      color: #00bf08;
      margin: 0;
    }

    svg {
      color: #00bf08;
      width: 10px;
      height: 10px;
    }
  }

  .main-option-info-order {
    // border: 1px solid red;
    top: 0;
    // padding-right: 15px;
    padding-right: 4vw;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // width: 100%;
    position: absolute;

    label {
      font-size: 11px;
      font-weight: 400;
      color: #666666;
    }
  }

  .main-option-container {
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background: rgba(255, 255, 255, 0.78);
    //border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    cursor: pointer;

    .main-option-container--icon {
      margin: 0 10px;

      .main-option-container--icon--svg {
        width: 20px;
        height: 20px;
      }
    }

    .main-option-container--desc {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px 0;

      p {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13.62px;

        margin: 0;

        color: #000000;
      }

      .main-option-container--desc--modality {
        font-weight: 600;
        //letter-spacing: 1px;
      }

      .main-option-container--desc--tax-info {
        display: flex;
        margin-left: 30%;
        justify-content: center;

        p {
          font-size: 13px;
        }
      }
    }

    .main-option-container--info {
      display: flex;
      width: 30%;
      flex-direction: column;
      // border: 1px solid red;
      margin-right: 20px;
      justify-content: center;
      align-items: flex-end;

      p {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        text-align: right;
        margin: 0;
        color: #000000;
      }

      .main-option-container--info--svg {
        width: 14px;
        height: 14px;
        color: #dc3545;
      }
    }
  }

  .main-otion--fidelities {
    display: flex;
    align-items: center;
    //justify-content: space-between;
    //padding: 0 20px;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    height: auto;
    min-height: 50px;
    cursor: pointer;
    //border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background: rgba(255, 255, 255, 0.78);

    .main-otion--fidelities--left {
      //display: flex;
      //align-items: center;
      //justify-content: center;
      margin: 0 10px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .main-otion--fidelities--right {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      margin-right: 10px;
      font-size: 10px;
      width: 100%;
      //border: 1px solid red;

      .main-otion--fidelities--right--header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;

        span {
          font-weight: 600;
        }
      }

      .main-otion--fidelities--right--body {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;

        .main-otion--fidelities--right--body--item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          .main-otion--fidelities--right--body--item--progress-bar {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 80%;
            height: 10px;
            background-color: #c1c1c1;
            border-radius: 3px;
            padding: 0 3px;

            .main-otion--fidelities--right--body--item--progress-bar--progress {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              height: 5px;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }

  .main-option--company-name {
    margin-top: 0 !important;
    position: absolute;
    top: -50px;
    left: 6px;
    max-width: 120px;
    font-size: 11px;
    width: 100%;
    color: #fff;
    background-color: #d00505;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: min-content;

    button {
      border: none;
      background-color: transparent;
      color: #fff;
      font-size: 10px;
      cursor: pointer;
      margin: 0;
      padding: 0 4px;
    }
  }

  .main-option--status-company {
    margin-top: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #6b6b6b;
    padding: 0 10px;
    top: -54px;
    width: 200px;
    right: 6px;
    position: absolute;
    border-radius: 20px;

    label {
      margin: 0;
      font-size: 11px;
      padding: 0 4px;
    }

    .option-title {
      font-family: "Lato", sans-serif !important;
      font-weight: 400 !important;
      margin-top: 0 !important;
      font-size: 10px;
      text-transform: uppercase;
    }

    .option-subtitle {
      margin-top: 0 !important;
      font-weight: 400 !important;
      font-size: 14px;
      color: #000000;
      font-family: "Rubik", serif !important;
    }

    .option-title-main {
      font-family: "Lato", sans-serif !important;
      // margin-top: 0px !important;
      // text-transform: uppercase;
      // font-weight: 400 !important;
      // font-size: 14px !important;
    }

    .color-store-closed {
      color: #ffffff;
    }

    .color-store-opened {
      color: #ffffff;
    }

    .color-store-loading {
      color: #ffffff;
    }

    .option-badge {
      color: $button-color-default;
      font-size: 10px;
    }
  }

  .company-opened {
    background-color: #00bf08;
  }

  .company-closed {
    background-color: $button-color-default;
  }

  .company-loading {
    color: $styleguide-gray-high;
  }

  .main-option-image {
    position: absolute;
    top: -80px;
    border: 5px solid #f2f2f2;
    border-radius: 50%;

    img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }
  }

  .main-options--modality-popup {
    gap: 6px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 90px;
    //left: 5vw;
    width: 100%;
    // max-width: 375px;
    height: auto;
    background-color: $styleguide-white;
    box-shadow: 0 10px 20px 6px rgba(0, 0, 0, 0.2);
    z-index: 1055;
    visibility: hidden;
    border-radius: 5px;
    padding: 10px 0;

    .main-options--modality-popup--delivery {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 40px;
      cursor: pointer;
      // border: 1px solid red;

      .main-options--modality-popup--delivery--icon {
        display: flex;
        width: 25%;
        height: 100%;
        justify-content: center;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .main-options--modality-popup--delivery--text {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
        // border: 1px solid blue;
        .main-options--modality-popup--delivery--text--top {
          p {
            margin: 0;
            font-family: "Open Sans", "sans-serif";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            color: $styleguide-gray-high;
          }
        }

        .main-options--modality-popup--delivery--text--bottom {
          p {
            margin: 0;
            font-family: "Open Sans", "sans-serif";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }

    .main-options--modality-popup--takeaway {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 40px;
      margin-bottom: 5px;
      cursor: pointer;

      .main-options--modality-popup--takeaway--icon {
        display: flex;
        width: 25%;
        height: 100%;
        justify-content: center;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .main-options--modality-popup--takeaway--text {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: flex-start;

        .main-options--modality-popup--takeaway--text--top {
          p {
            margin: 0;
            font-family: "Open Sans", "sans-serif";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 17px;
            color: $styleguide-gray-high;
          }
        }

        .main-options--modality-popup--takeaway--text--bottom {
          p {
            margin: 0;
            font-family: "Open Sans", "sans-serif";
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }
  }

  .main-options--about-popup {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    top: 30px;
    width: 100%;
    height: 100vh;

    // background-color: green;
    background-color: $styleguide-white;
    box-shadow: 0 -1px 20px 1px rgba(0, 0, 0, 0.2);

    z-index: 11;
    visibility: hidden;

    .main-options--about-popup--header {
      width: 100%;
      height: 90%;

      display: flex;
      justify-content: space-around;
      align-items: flex-start;
    }

    //Navegação das abas
    .nav-tabs {
      .nav-link {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 1px;
        color: #000000;
      }

      .tab-item-selected {
        .nav-link {
          font-style: normal;
          font-weight: 700;
          color: #000000;
          border-bottom: 1px solid #000000;
        }
      }
    }

    //Conteúdo das tabs
    .main-options--about-popup--tab {
      width: 100%;
      height: 90%;

      .main-options--about-popup--tab--about {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 20px;
        overflow-y: auto;
        gap: 20px;

        .main-options--about-popup--tab--about--bio {
          label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            color: #666666;
          }
        }

        .main-options--about-popup--tab--about--address {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;

          strong {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 10px;
          }

          .main-options--about-popup--tab--about--address--info {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 10px;

            label {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;

              color: #666666;
            }
          }
        }

        .main-options--about-popup--tab--about--working-period {
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          width: 100%;

          strong {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
            margin-bottom: 10px;
          }

          .main-options--about-popup--tab--about--working-period--day {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;

            p {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 22px;
              color: #666666;
              margin: 0;
            }

            .today {
              color: #00bf08;
            }
          }
        }
      }

      .main-options--about-popup--tab--payment {
        display: flex;
        padding: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        .main-options--about-popup--tab--payment--delivery-man {
          display: flex;
          flex-direction: column;

          label {
            margin-top: 15px;
          }

          strong {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;

            color: #000000;
          }

          .main-options--about-popup--tab--payment--delivery-man--category {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            label {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              color: #666666;
            }

            .main-options--about-popup--tab--payment--delivery-man--category--data {
              display: flex;
              flex-direction: row;
              gap: 20px;
              align-items: center;
              width: 50%;

              img {
                width: 44px;
                height: 36px;
              }

              svg {
                width: 44px;
                height: 36px;
              }

              label {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                //letter-spacing: -0.226995px;
                color: #666666;
              }
            }
          }
        }
      }
    }
  }

  .active-modality-popup {
    visibility: visible;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 200ms;
  }

  .active-about-popup {
    animation: moveFromBottom 0.3s;
    visibility: visible;
  }

  .divider {
    border-right: 1px solid #6b6b6b;
  }
}

.main-options-large-devices {
  flex-direction: row;
  margin-top: 0;
  padding: 8px 4px;
  min-width: 400px;
  align-items: center;

  .main-option-container {
    margin-top: 0;
  }

  .main-otion--fidelities {
    margin-top: 0;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveFromBottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}

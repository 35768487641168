@import "../../assets/scss/Variables";

.component-select {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 40px;
   padding: 0 10px;
   border-radius: $button-border-radius;

   -moz-appearance: none; /* Firefox */
   -webkit-appearance: none; /* Safari and Chrome */
   appearance: none;
}

.valid{
   border: 1px solid $border-color;
}

.invalid{
   border: 1px solid $button-color-default;
}

select[disabled]{
   background-color: #dadada
}

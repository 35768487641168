.modal-number-address {
  min-width: 100%;

  .modal-number-address--header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    label {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .modal-number-address--body {
    gap: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input {
      width: 50%;
      text-align: center;
    }

    input[disabled] {
      background-color: #dadada
    }

    label {
      margin: 0;
      font-size: 14px;
    }

    .modal-number-address--body--title {
      font-size: 14px;
    }

    .modal-number-address--body--label-address {
      width: 80%;
      font-weight: 600;
      text-align: center;
      margin-bottom: 10px;
    }

    .modal-number-address--body--no-number {
      gap: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }

  .modal-number-address--footer {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

  }

}
@import "../../assets/scss/Variables";

.component-button {
  font-size: $button-font-size;
  width: 100%;
  height: 40px;
  border-radius: $button-border-radius;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: $button-color-default;

  svg {
    width: $button-svg-width;
    height: $button-svg-height;
    color: $button-color-text;
  }

  p {
    margin: 0;
    padding: 0;
    color: $button-color-text;
  }

  &:hover {
    background-color: darken($button-color-default, 3%);
  }
}

.button-props-outline {
  background-color: $button-color-text;
  border: 1px solid $button-color-default !important;

  svg {
    color: $button-color-default;
  }

  p {
    margin: 0;
    padding: 0;
    color: $button-color-default;
  }

  &:hover {
    background-color: darken($button-color-text, 5%);
  }
}

.button-props-disabled {
  color: #666666 !important;
  border-color: #666666 !important;
  background-color: rgba(102, 102, 102, 0.4) !important;
}

.modal-pix-payment {
  .modal-pix-payment--header {
    .modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      div {
        width: 100%;
      }

      .modal-pix-payment--header--left {
        button {
          background-color: transparent;
          border: none;
          color: #3d3d3d;
        }
      }

      .modal-pix-payment--header--center {
        display: flex;
        justify-content: center;

        h2 {
          color: #3d3d3d;
          line-height: 100%;
          margin: 0;
          width: 100%;
          text-align: center;
          font-size: 18px;
          white-space: nowrap;
          font-weight: 600;
          -webkit-font-smoothing: antialiased;
        }
      }
    }
  }

  .modal-pix-payment--body {
    .modal-pix-payment--body--top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .modal-pix-payment--body--top-secure {
        max-width: 120px;
        width: 100%;
      }

      .modal-pix-payment--body--top-qrimage {
        max-width: 250px;
        width: 100%;
      }

      .modal-pix-payment--body--top--pix {
        width: 100%;

        h1 {
          color: #3d3d3d;
          font-size: 20px;
          font-weight: 600;
          width: 100%;
          text-align: center;
          margin-top: 5px;
        }

        .modal-pix-payment--body--top--pix--code {
          display: flex;

          .modal-pix-payment--body--top--pix--code--input {
            width: 100%;
            height: 40px;
            border: 1px solid #3d3d3d;
            border-radius: 4px;
            padding: 0 10px;
            font-size: 14px;
            color: #7c7c7c;
            font-weight: 600;
            -webkit-font-smoothing: antialiased;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            background-color: #f5f5f5;
            border-right: none;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          .modal-pix-payment--body--top--pix--code--input-copy {
            border: none;
            width: 40px;
            aspect-ratio: 4 / 4;
            background-color: #f5f5f5;
            border-radius: 5px;
            border: 1px solid #3d3d3d;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            color: #df2833;
          }
        }
      }
    }

    .modal-pix-payment--body--center {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 18px;
        margin-top: 20px;
        font-weight: 600;
        max-width: 70%;
      }
    }
  }

  .modal-pix-payment--footer {
    background-color: #f3f3f3;

    .modal-pix-payment--footer--progress {
      width: 100%;
      text-align: left;

      h2 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 100%;
        color: #3d3d3d;
      }

      h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 100%;
      }
    }
  }
}

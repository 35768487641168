@import "../../assets/scss/Variables.scss";

.modal-blocked {
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   .modal-content {
      border-radius: 20px !important;
      padding: 5px;
      margin: 20px;

      .modal-blocked--content {
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         padding: 10px;
         gap: 15px;

         span {
            text-align: center;
            font-size: 20px;
         }

         svg {
            width: 100px;
            height: 100px;
            color: $styleguide-red;
         }

         .modal-blocked--content--red {
            color: $styleguide-red;
            text-align: center;
            font-size: 20px;
         }
      }
   }
}

@import "../scss/Variables.scss";




body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 12px;

  height: 100dvh;
  position: fixed;
  overflow: hidden !important;
  overflow: unset;



  .modal{
    //height: unset;
    //top: unset;

    animation: moveFromBottom 0.3s;



    .modal-dialog{

     position: fixed;
      bottom: 0vh;
      margin: 0;
      left: 0%;
      right: 0%;

      .modal-content{
          border-radius: 20px 20px 0 0;
      }
    }
  }

  .section-home {
    height: 100svh;
    width: 100vw;
    overflow: auto;
    -webkit-overflow-scrolling: touch;



  }
  .selected-home {
    padding-bottom: 65px !important;
  }

  .selected-home-resume-bar {
    padding-bottom: 119px !important;
  }

}

.display-none {
  display: none !important;
}

.card {
  cursor: pointer;
  background-color: darken(#ffffff, 2%);

  &:hover {
    background-color: #ffffff;
    box-shadow: 0 8px 15px 1px rgba(0, 0, 0, 0.2);
  }
}

.footer-button-left {
  cursor: pointer;
  padding: 10px 30px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 3px;
  background-color: #656565;
}

.footer-button-right {
  cursor: pointer;
  padding: 10px 30px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 3px;
  background-color: $button-color-default;
}

.footer-button-right-disabled {
  padding: 10px 30px;
  font-size: 14px;
  border-radius: 3px;
  color: #666666 !important;
  border-color: #666666 !important;
  background-color: rgba(102, 102, 102, 0.4) !important;
}

.footer-button {
  cursor: pointer;
  padding: 10px 30px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 3px;
  background-color: $button-color-default;
}

.swal2-header{
  padding: 0 !important;
}

.swal2-icon {
  //width: 4em !important;
  //height: 4em !important;
  margin: 0 0 1em 0 !important;
}

.swal2-title {
  font-size: 16px !important;
}

.swal2-html-container {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.section-loading {
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  //background-color: #EA5457;
}

.error-boundary {
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .error-boundary--body {
    width: 100%;
    margin: auto;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 50vw;
      object-fit: contain;
      margin-bottom: 10px;
    }

    h6 {
      margin: 0;
      font-size: 14px;
      text-align: center;
    }

    label {
      font-size: 14px;
    }

    pre {
      font-size: 12px;
      white-space: normal;
      margin-bottom: 10px;
    }

    button {
      max-width: 40%;
      border-color: $button-color-default;
      background-color: $button-color-default;
    }

    div {
      display: flex;
      margin-top: 20px;
      align-items: center;
      flex-direction: column;
    }
  }
}

.payment-info {
  .payment-info--header {
  }

  .payment-info--body {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .payment-info--body--top {
      font-size: 18px;
      color: $button-color-default;
    }

    .payment-info--body--center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      label {
        font-size: 16px;
        font-weight: 400;

        span {
          font-weight: 600;
        }
      }

      .payment-info--body--center--copy {
        // border: 1px solid red;
        display: flex;
        width: 25px;
        height: 25px;
        align-items: center;
        justify-content: center;
      }
    }

    .payment-info--body--bottom {
      display: flex;
      padding-top: 15px;
      flex-direction: column;

      label {
        font-size: 16px;
        margin: 0;
      }

      a {
        color: #23a441;
        cursor: pointer;
        font-size: 14px;
        padding: 8px 16px;
        border-radius: 3px;
        text-decoration: none;
        //background-color: #23A441;
      }
    }
  }
}

.readonly-group {
  pointer-events: none !important;

  label {
    background-color: transparent !important;
    color: $text-color-readonly-group !important;
  }

  button {
    color: $text-color-readonly-group !important;
    border-color: $border-color-readonly-group !important;
    background-color: $background-color-readonly-group !important;
  }

  svg {
    color: $text-color-readonly-group !important;
  }
}

@import "./Media";
@import "./Sidebar";
@import "./Themes";

@keyframes moveFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
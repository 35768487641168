@import "../../assets/scss/Variables.scss";



.modal-companies {
  min-width: 100%;
  max-height: 100%;
  .modal-header {

    .modal-title{

 width: 100%;
 text-align: center;
    }
  }
  
  .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    label {
      margin: 0;
    }
    
    label:first-child {
      font-size: 16px;
      margin-bottom: 4px;
    }
    
    label:last-child {
      font-size: 14px;
    }
  }
  
  .location-methods {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    
    .separator {
      padding-top: 6px;
    }
    
    .left-side {
      width: 33.33%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      
      button {
        width: 80%;
        font-size: 14px;
      }
    }
    
    .center {
      width: 33.33%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      
      input {
        width: 80%;
        font-size: 14px;
      }
    }
    
    .right-side {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      .react-select {
        width: 80%;
        font-size: 14px;
        margin-bottom: 10px;
      }
      
    }
  }
  
  .status {
    display: flex;
    margin: 10px 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #656565;

    border-bottom: 1px solid #dee2e6;
    padding: 10px;
    
    label {
      font-size: 14px;
      margin: 20px 0 0 0;
    }
    
    .loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .result {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .companies {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      
      .company {
        cursor: pointer;
        padding: 8px 30px;
        border-radius: 3px;
        border: 1px solid #656565;
        margin: 10px 5px 0 5px;
        
        &:hover {
          color: #FFFFFF;
          background-color: darken(#656565, 2%);
        }
      }
      
      p {
        margin: 10px;
        color: $button-color-default;
        font-size: 12px;
      }
    }
    
    .info {
      color: #656565;
    }
    
    .success {
      color: #23B441;
    }
    
    .error {
      color: $button-color-default;
    }
  }

  .company-list{
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    max-height: 50vh;

    .company-list--tittle{
      font-weight: 600;
      width: 100%;
      text-align: center;
    }
  .company {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    border-bottom: 1px solid #dee2e6;
    padding: 16px;
    

    .company--img{
      display: flex;
      width: 65px;
      align-items: center;
      display: flex;

     
      border-radius: 12px;
      cursor: pointer;
      img {
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;

        border-radius: 12px;
      }
    }

    .companies--company{
      display: flex;
 
      flex-direction: column;
      min-width: 220px;
      cursor: pointer;
      
     

      .companies--company--name{
        display: flex;
        width: 100%;

        font-weight:700;
      }

      .companies--company--modalities{
        display: flex;
        width: 100%;
        height:fit-content;
    
        flex-direction: row;

       
        .companies--company--modalities--active{
          display: flex;
          padding-right: 14px ;
          width:fit-content;
          height: fit-content;
          font-size: 14px;
       
        }

       
      }

      .companies--company--st-site{
        display: flex;
        font-size: 14px;
        color: $button-color-default;
      }
      .companies--company--st-site--open{
        
        color: green;
      }
    }
  }
}

.modal-footer {
  text-align: center;
}
  .information {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    label {
      margin: 0;
      font-size: 14px;
    }
  }
}
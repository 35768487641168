$theme-1-color-1: #342224;
$theme-1-color-2: #c23b35;
$theme-1-color-3: #ffffff;
$theme-1-color-4: #4f5256;

//StyleGuide
$styleguide-black: #000000;
$styleguide-white: #ffffff;
$styleguide-white-low: #f2f2f2;
$styleguide-gray-high: #666666;
$styleguide-gray-low: #f3f3f3;

$styleguide-orange: #fcb82c;
$styleguide-yellow: #f8fc2c;
$styleguide-green: #00BF08;
$styleguide-red: #df2833;
$styleguide-blue: #0a6dc2;
$styleguide-blue-low: #9db8f8;

$border-color: #dadada;
$border-radius: 10px;

$text-color-default: #dc3545;
$text-color-readonly-group: #666666;

$border-color-readonly-group: #666666;
$background-color-readonly-group: rgba(102, 102, 102, 0.4);


// Modal
$modal-border-radius: 20px;

// Buttons
$button-svg-width: 12px;
$button-svg-height: 12px;

$button-font-size: 12px;
$button-border-radius: 10px;

$button-color-text: #FFF;
$button-color-default: #D00505;
$button-color-readonly-group: #eeeeee;

@import "../../assets/scss/Variables.scss";
//.modal {
//
//}
.builder {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  position: relative !important;
  background-color: #fff;
  //height:100vh;
  //height: min-content;
  //max-height: 90vh;
  //border: 1px solid red;

  .builder--body {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    // justify-content: space-between;
    padding: 0;
    padding-bottom: 60px;
    background-color: #ffffff;
    //max-height: 100%;

    .left-side-builder {
      width: 75%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      // overflow-y: auto;
      border-radius: 3px;
      margin-top: 50px;

      .amount-product-flavour {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
          margin: 0;
          font-size: 18px;
        }

        .button-group {
          display: flex;
          align-items: center;
          justify-content: center;

          label {
            margin: 0;
            font-size: 40px;
            padding: 10px 30px;
          }

          svg {
            font-size: 40px;
          }
        }
      }

      .card-combo-no-items {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        label {
          font-size: 18px;
        }

        svg {
          font-size: 48px;
          color: #04a53d;
        }
      }

      .card-optional,
      .card-additional,
      .card-flavour,
      .card-beverage {
        cursor: default;

        .card-body {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 0;

          .number-control {
            flex: 1 1 10%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .button-icon {
              padding: 1px;
              display: flex;
              cursor: pointer;
              font-size: 10px;
              flex-direction: column;
            }

            .button-number {
              margin: 0;
              color: #ffffff;
              font-weight: 600;
              padding: 4px 8px;
              border-radius: 3px;
              pointer-events: none;
              font-size: 11px;
              background-color: #1b1e21;
            }
          }

          .card-body-content {
            flex: 1 1 90%;
            display: flex;
            cursor: pointer;
            margin-left: 10px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
        }
      }

      .builder-graph {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .lbl-flavour-numbers {
          margin: 0;
        }

        .flavour-numbers {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          max-width: 100vw;
          justify-content: center;

          button {
            cursor: pointer;
            font-size: 14px;
            padding: 4px 18px;
            margin: 10px 5px;
            background-color: #fff;
            color: #000;
            border: 0.1em solid #c4c4c4;
            border-radius: 3px;
            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
          }

          .active {
            color: #ffffff;
            background-color: #23b441;
            border: 0.1em solid darken(#23b441, 5%);
          }

          button:disabled {
            pointer-events: none !important;
            color: $text-color-readonly-group !important;
            background-color: $button-color-readonly-group !important;

            &::placeholder {
              color: $text-color-readonly-group !important;
            }
          }
        }

        .flavours-graph {
          display: flex;
          flex-direction: row-reverse;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin-top: 10px;

          .flavour-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin: 0 5px 10px 5px;
            border-radius: 3px;
            border: 1px solid #d8d9d7;

            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);

            &:hover {
              box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.5);
            }

            label {
              margin: 0 !important;
            }

            .flavour-description {
              cursor: pointer;
              padding: 2px 10px;
              font-size: 14px;
              border-radius: 3px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              display: flex;
              border: none;
              align-items: center;
              justify-content: center;
              background-color: transparent;
            }

            .flavour-delete {
              cursor: pointer;
              padding: 2px 10px;
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
              background-color: $button-color-default;

              svg {
                color: #ffffff;
                padding: 0;
                margin: 0;
              }
            }
          }
        }

        .chart-graph {
          display: flex;
          padding: 6px 12px 6px 10px;
          margin-top: 10px;
          align-items: center;
          justify-content: center;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("../../../src/assets/img/builder/tabua-02.png");
          position: relative;

          .legend {
            color: #ffffff;
            font-weight: 400;
            padding: 5px 20px;
            background-color: $button-color-default;
          }

          pattern {
            image {
              //x: 0 !important;
              //y: 0 !important;
            }
          }
        }

        .lbl-info-ingredients {
          text-align: center;
          margin-top: 20px;
          color: #6c757d;
        }

        .cursor {
          position: relative;

          img {
            position: absolute;
            bottom: 55px;
            left: 20px;
            animation: bounce 4s ease-in-out infinite;
          }
        }
      }

      .product-complements {
        width: 100%;
      }

      .left-side-builder--observation-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        flex-direction: column;
        padding: 5px;
        opacity: 50%;
        gap: 10px;

        .left-side-builder--observation-content--header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: auto;
          flex-direction: row;
        }

        .left-side-builder--observation-content--body {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: auto;
          flex-direction: column;

          textarea {
            width: 100%;
            height: 105px;
            resize: none;
          }
        }
      }
    }

    .hide-left-side-builder {
      display: none;
    }

    .right-side-builder {
      width: auto;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;

      .close-button-modal-builder {
        position: fixed;
        top: 10px;
        // bottom: 5px;
        left: 10px;
        z-index: 1056;
        color: #c6c6c6;
        opacity: 0.7;
        cursor: pointer;

        svg {
          border-radius: 50%;
          // box-shadow: 0 4px 16px 2px rgba(0, 0, 0, 0.6);
          width: 30px;
          height: 30px;
        }
      }

      .card-img {
        object-fit: cover;
        height: 100%;
      }

      .card {
        width: 100%;
        border: 1px solid #dcdfe0;
        border-radius: 3px;
        background-color: #ffffff;

        .card-body {
          padding: 0;
          position: relative;
          //display: flex;
          //align-items: center;

          .card-info {
            background-color: #ffffff;
            padding: 10px;
            width: 100%;
            margin-top: -18px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .card-title {
              font-size: 18px;
            }

            .card-description {
              overflow-y: auto;
              text-align: justify;
              height: auto;
              opacity: 50%;
              margin-bottom: 15px;
              padding: 5px 10px;
            }

            .flag-image {
              position: absolute;
              right: 0;
              top: 30px;
              z-index: 2;

              .flag-image--body {
                // max-width: 150px;
                height: 46px;
                position: relative;
                box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);

                div {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  label {
                    margin: 0;
                    padding: 10px 10px;
                    font-size: 20px;
                    color: #ffffff;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }

                &:after {
                  content: "";
                  position: absolute;
                  left: -15px;
                  bottom: 0;
                  width: 0;
                  height: 0;
                  border-right-style: solid;
                  border-right-width: 15px;
                  border-right-color: inherit;
                  border-top: 23px solid transparent;
                  border-bottom: 23px solid transparent;
                }
              }
            }

            .card-img-top {
              width: 100%;

              img {
                object-fit: cover;
                height: 100%;
              }
            }

            .card-image-right {
              display: none;
            }

            .card-price {
              display: flex;
              width: 100%;
              flex-direction: row;
              align-items: flex-end;
              justify-content: flex-end;

              .card-price-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                height: auto;

                p {
                  font-size: 20px !important;
                  font-weight: 600;
                  //font-family: "Rubik", sans-serif !important;
                  margin: 0;
                }
              }

              .card-price-item-back {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                justify-content: flex-end;

                p {
                  text-decoration: line-through;
                  font-size: 14px !important;
                  font-weight: 400;
                  //font-family: "Rubik", sans-serif !important;
                  margin: 0;
                }
              }
            }
          }

          .card-image-right {
            .flag-image {
              position: absolute;
              right: 0;
              top: 30px;
              z-index: 2;

              .flag-image--body {
                // max-width: 150px;
                height: 46px;
                position: relative;
                box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);

                div {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  label {
                    margin: 0;
                    padding: 10px 10px;
                    font-size: 20px;
                    color: #ffffff;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }

                &:after {
                  content: "";
                  position: absolute;
                  left: -15px;
                  bottom: 0;
                  width: 0;
                  height: 0;
                  border-right-style: solid;
                  border-right-width: 15px;
                  border-right-color: inherit;
                  border-top: 23px solid transparent;
                  border-bottom: 23px solid transparent;
                }
              }
            }
          }
        }

        .card-amount-flavour {
          gap: 10px;
          display: flex;
          flex-direction: column;
          // padding: 10px;
          width: 100%;
          align-items: center;
          justify-content: center;

          .card-image-top {
            width: 100%;
            display: flex;
            max-height: 40vh;
            border-radius: 6px;
            //box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.2);
            mix-blend-mode: multiply;

            .teste {
              height: 100%;
            }

            img {
              object-fit: cover;
              height: 100%;
            }
          }
        }

        &:hover {
          box-shadow: unset !important;
          background-color: #ffffff !important;
        }
      }
    }

    .center-builder {
      width: 100%;

      .card {
        display: flex;
        flex-direction: row;
        align-items: center;

        .card-img-top {
          width: 23%;
          object-fit: cover;

          img {
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }
  }

  .builder--footer {
    display: flex;
    padding: 0;
    width: 100%;
    height: 60px;
    //margin-bottom: 10px;
    background-color: #ffffff;
    box-shadow: 0 -2px 14px 2px rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 0;

    .footer-buttons {
      width: 33%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 15px;

      .button-group {
        display: flex;
        width: 30%;
        height: 45px;
        border-radius: 10px;
        background-color: #f3f4f6;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px 0 15px;

        label {
          margin: 0 !important;
          font-size: 15px;
          color: darken(#c6c6c6, 50%);
        }

        svg {
          font-size: 16px !important;
          color: darken(#c6c6c6, 30%);
          cursor: pointer;
        }
      }

      .button-footer-button-right {
        display: flex;
        width: 68%;
        border-radius: 8px;
        height: 45px;

        svg {
          display: none;
        }

        label {
          display: flex;
          padding: 0;
          width: 100%;
          height: 100%;
          justify-content: center;
          text-align: center;
          align-items: center;
          font-size: 12px;
          border-radius: 8px;
        }
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.skeleton-container {
  width: 100%;
  padding: 0 16px;

  &:not(:first-child) {
    margin-top: 20px;
  }

  .skeleton-container--item {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;

    .skeleton-container--item--right {
      width: 100%;
    }
  }
}

@keyframes bounce {
  0% {
    bottom: 55px;
  }
  50% {
    bottom: 70px;
  }
  100% {
    bottom: 55px;
  }
}

@import "../../assets/scss/Variables.scss";

.collapse-card-item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0;
  // padding: 0 15px;
  position: relative;
  flex-direction: column;

  p {
    margin: 0;
  }

  .collapse-card-item--content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 0 15px;
    position: relative;
    //border: 1px solid blue;

    .collapse-card-item--content--left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      max-width: 62px;
      height: 100%;
      max-height: 62px;
      border: 1px solid #dcdfe0;
      border-radius: 10px;
      //border:1px solid red;

      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }

    .collapse-card-item--content--center {
      width: 75%;
      padding-left: 15px;
      padding-right: 4px;
      position: relative;

      h5 {
        font-family: "Open Sans", "sans-serif";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 12px;
        padding: 0;
      }
      .collapse-card-item--content--center--text--description {
        font-family: "Open Sans", "sans-serif";
        font-style: normal;
        font-size: 12px;
        overflow: hidden;
        margin: 0;
        padding: 0;
        max-width: 100%;
      }
      p {
        font-family: "Open Sans", "sans-serif";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 12px;
      }

      .collapse-card-item--content--center--ingredients {
        //position: absolute;
        background-color: transparent;
        margin-top: 6px;
        border: 1px solid #dcdfe0;
        border-radius: 10px;
        padding: 4px;
        width: 96%;

        span {
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          .collapse-card-item--content--center--ingredients--desc {
            margin: 0 0 0 3px;
            padding: 0;
            font-family: "Open Sans", "sans-serif";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            //line-height: 12px;
          }
        }
      }
    }

    .collapse-card-item--right {
      .radio-button {
        cursor: pointer;
      }
    }

    .collapse-card-item--content--right {
      position: absolute;
      right: 15px;

      .collapse-card-item--right--radio {
        // width: 50px;
        // height: 50px;
      }
    }
  }

  .collapse-card-item--divider {
    margin-top: 15px;
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #dadada;
  }

  .ingredient-removed {
    text-decoration: line-through;
  }
}

.ingredient-type {
  .collapse-card-item--content {
    h5 {
      padding: 0;
      margin: 0 5px 0 0;
    }

    .collapse-card-item--content--center {
      display: flex;
      flex-direction: row;
    }
  }
}

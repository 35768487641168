.modal-success-order {
   border-radius: 5px;
   //padding: 10px;
   .modal-success-order--header {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      svg {
         width: 40px;
         height: 40px;
      }
   }

   .modal-success-order--body {
      // border: 1px solid green;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px 3px 15px 3px;
      .modal-success-order--body--top {
         // border: 1px solid blue;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
      }

      .modal-success-order--body--bottom {
         // border: 1px solid red;
         display: flex;
         width: 100%;
         flex-direction: column;
         justify-content: center;
         align-items: center;

         .modal-success-order--body--bottom--top {
         }

         .modal-success-order--body--bottom--center {
            width: 100%;
            padding: 0 20px;
            span {
               display: flex;
               flex-direction: row;
               justify-content: center;
               align-items: center;
            }
         }
         .modal-success-order--body--bottom--footer {
            // border: 1px solid red;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            a {
               text-decoration: none;
               color: #23a441;
            }
         }
      }
   }

   .modal-success-order--footer {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
   }
}

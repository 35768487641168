.schedule-working-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  cursor: pointer;

  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
@import "../../assets/scss/Variables.scss";

$min-desktop-xxl: "only screen and (min-width: 2561px)";

$max-desktop-xl: "only screen and (max-width: 2560px)";
$max-desktop-lg: "only screen and (max-width: 1920px)";
$max-desktop-md: "only screen and (max-width: 1600px)";
$max-desktop-sm: "only screen and (max-width: 1280px)";

$max-tablet-lg: "only screen and (max-width: 1024px)";
$max-tablet-md: "only screen and (max-width: 900px)";
$max-tablet-sm: "only screen and (max-width: 768px)";

$max-mobile-lg: "only screen and (max-width: 600px)";
$max-mobile-md: "only screen and (max-width: 480px)";
$max-mobile-sm: "only screen and (max-width: 380px)";

$max-mobile-height-xl: "only screen and (max-height: 900px)";
$max-mobile-height-lg: "only screen and (max-height: 850px)";
$max-mobile-height-lm: "only screen and (max-height: 800px)";
$max-mobile-height-md: "only screen and (max-height: 750px)";
$max-mobile-height-ms: "only screen and (max-height: 700px)";
$max-mobile-height-sm: "only screen and (max-height: 600px)";
$max-mobile-height-xs: "only screen and (max-height: 550px)";

// quando passa do desktop de 2560px
@media #{$min-desktop-xxl} {
  .section-main {
    .main-options {
      display: none;
    }
  }
}

// Max => Análise de cima para baixo

// 2560px
@media #{$max-desktop-xl} {
  .section-header {
    height: 300px !important;

    .header-main-options-container {
      // display: none;
      display: block;

      .main-options {
        gap: 10px;

        .main-options--modality-popup {
          top: 60px;
          right: 6vw;
          left: -3vw;
        }

        .main-option {
          display: flex;
          background: rgba(255, 255, 255, 0.78) !important;
          width: 100%;
          border-radius: 8px;
        }

        .main-option-image {
          display: none !important;
        }

        .main-option-about {
          display: none !important;
        }

        .main-option-info-order {
          display: none !important;
        }

        .main-option--status-company {
          display: none !important;
        }

        .main-option--company-name {
          display: none !important;
        }
      }
    }
  }

  .section-content {
    padding: 0 25%;

    .right-side-content,
    .right-side-content-fixed {
      .order-preview {
        .order-title {
          font-size: 18px !important;
        }

        .order-content {
          max-height: 350px !important;

          .order-products {
            .product-item {
              padding: 10px;

              .number-control {
                .button-icon {
                  font-size: 12px;
                }

                .button-number {
                  margin: 0;
                  font-size: 12px;
                  font-weight: 600;
                  padding: 4px 10px;
                }
              }

              .product-description {
                padding-left: 10px;

                label {
                  margin: 0;
                }
              }

              .product-price {
                label {
                  font-size: 14px;
                }

                button {
                  padding: 2px;
                  font-size: 10px;
                }
              }
            }

            h5 {
              margin: 10px !important;
              font-size: 16px !important;
            }
          }

          .order-beverages {
            .beverage-item {
              .number-control {
                flex: 1 1 10%;

                .button-icon {
                  cursor: pointer;
                  font-size: 12px;
                }

                .button-number {
                  font-size: 10px !important;
                }
              }

              .description {
                flex: 1 1 65%;
                display: flex;
                padding-left: 10px;
                flex-direction: column;
                justify-content: center;

                label {
                  margin: 0;
                  font-size: 14px !important;
                }
              }

              .price {
                flex: 1 1 25%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                label {
                  font-size: 14px !important;
                }

                button {
                  padding: 2px;
                  font-size: 10px;
                }
              }
            }

            .beverage-no-item {
              h6 {
                margin: 10px !important;
                font-size: 14px !important;
              }

              label {
                margin: 0;
                font-size: 16px;
                cursor: pointer;
              }
            }
          }
        }

        .order-props {
          .delivery-tax {
            font-size: 16px !important;
          }

          .coupon {
            font-size: 16px !important;

            input {
              font-size: 14px !important;
            }
          }
        }

        .order-total {
          font-size: 18px !important;

          span {
            font-size: 16px !important;
          }
        }

        .order-buttons {
          label {
            font-size: 14px !important;
          }
        }
      }

      .order-preview-no-items {
        padding-top: 100% !important;
        font-size: 16px !important;
        justify-content: unset !important;
      }
    }

    .left-side-content {
      margin-top: 0 !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;

      // .menu-options {
      //   .category-selector {
      //     top: 20px !important;
      //     margin-top: 0 !important;
      //   }
      // }
    }

    .my-orders {
      bottom: 0 !important;
      right: unset !important;
      left: 2vw !important;
      top: unset !important;
    }
  }

  .section-main {
    .menu-options,
    .menu-options-fixed {
      label {
        padding: 10px !important;
        font-size: 14px !important;
      }

      .category-selector {
        top: 0 !important;
      }
    }

    .main-options {
      width: 100%;
      display: none;

      .main-option {
        // padding: 0 40px !important;
        align-items: center !important;
        justify-content: center !important;

        .option-title {
          text-align: center !important;
          font-size: 18px !important;
          text-transform: uppercase;
        }

        .option-subtitle {
          font-weight: bold;
          font-size: 16px;
          color: #000000;
          text-align: center !important;
        }

        .color-store {
          color: $button-color-default;
        }
      }

      .separator-option {
        margin-top: 20px !important;
      }
    }
  }

  .section-combos {
    .combos-content {
      .card-combo {
        flex: 0 1 calc(33.33% - 22px);

        .card-img {
          width: 200px;
          height: 180px;
        }
      }
    }
  }

  .section-products {
    .content-products {
      .product {
        // padding: 20px 10px 0 10px;

        .product-content {
          .card-product {
            flex: 0 1 calc(33.33% - 22px);
            margin-bottom: 10px;
            margin-right: 10px;

            .card-img {
              width: 180px !important;
              height: 180px !important;
            }
          }
        }
      }
    }
  }

  .section-beverages {
    .beverage-content {
      .card-category {
        flex: 0 1 calc(33.33% - 22px) !important;

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-img {
          width: 180px !important;
          height: 180px !important;
        }
      }

      .card-beverage {
        flex: 0 1 calc(33.33% - 22px) !important;

        .card-img {
          width: 200px !important;
          height: 180px !important;
        }

        .card-body {
          // padding: 8px 12px !important;

          .card-title {
            font-size: 16px !important;
          }

          .card-description {
            font-size: 14px !important;
          }

          .card-price {
            font-size: 16px !important;
          }
        }
      }
    }
  }

  .builder {
    .builder--body {
      // max-height: 700px !important;
    }

    .left-side-builder {
      .card-size {
        // flex: 0 1 calc(25% - 22px);

        .card-body {
          padding: 10px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 100px !important;
          height: 90px !important;
        }

        .card-description {
          margin: 0 !important;
        }
      }

      .card-flavour {
        flex: 0 1 calc(33.33% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 80px !important;
          height: 80px !important;
        }
      }

      .card-ingredient {
        flex: 0 1 calc(20% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }
        }
      }

      .card-additional {
        flex: 0 1 calc(25% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }
      }

      .card-optional {
        flex: 0 1 calc(25% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }
      }

      .card-beverage {
        flex: 0 1 calc(33.33% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 80px !important;
          height: 80px !important;
        }
      }
    }

    .right-side-builder {
      width: 50% !important;

      .card {
        height: 100% !important;

        .card-body {
          display: flex !important;
          flex-direction: column-reverse !important;
          justify-content: flex-end !important;

          .card-image-top {
            width: 100% !important;
          }

          .card-image-right {
            display: flex !important;
          }

          .card-info {
            margin-top: 0 !important;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
          }
        }

        .card-img-top {
          max-height: 250px;
        }
      }
    }
  }

  .modal-flavours {
    .card-flavours {
      flex: 0 1 calc(50% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 100px !important;
        height: 90px !important;
      }
    }
  }

  .modal-ingredients {
    .card-ingredient {
      flex: 0 1 calc(20% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 60px !important;
        }
      }
    }

    .card-optional {
      flex: 0 1 calc(25% - 22px);

      .card-body {
        padding: 8px !important;
        flex-direction: row !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 60px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }
    }
  }

  .modal-beverages {
    .card-beverage {
      flex: 0 1 calc(33.33% - 22px);

      .card-body {
        padding: 4px 8px !important;

        .card-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100px;
          font-size: 14px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 90px !important;
        height: 90px !important;
      }
    }
  }

  .order-preview {
    .order-preview--body {
      padding: 0 25%;
    }
  }

  .profile {
    .profile--user {
      .profile--user--body {
        padding: 0 25%;
      }

      .personal-info {
        .personal-info--body {
          padding: 0 25%;
        }
      }

      .addresses-info {
        .addresses-info--body {
          padding: 0 25%;
        }
      }

      .orders-info {
        .orders-info--body {
          padding: 0 25%;
        }
      }

      .order-progress {
        .order-progress--body {
          padding: 0 25%;
        }
      }
    }
  }
}

// 1920px
@media #{$max-desktop-lg} {
  .section-header {
    height: 300px !important;
  }

  .section-content {
    .right-side-content,
    .right-side-content-fixed {
      .order-preview {
        .order-title {
          font-size: 16px !important;
        }

        .order-content {
          max-height: 680px !important;

          .order-products {
            .product-item {
              padding: 10px;

              .number-control {
                .button-icon {
                  font-size: 12px;
                }

                .button-number {
                  margin: 0;
                  font-size: 12px;
                  font-weight: 600;
                  padding: 4px 10px;
                }
              }

              .product-description {
                padding-left: 10px;

                label {
                  margin: 0;
                }
              }

              .product-price {
                label {
                  font-size: 14px;
                }

                button {
                  padding: 2px;
                  font-size: 10px;
                }
              }
            }

            h5 {
              margin: 10px !important;
              font-size: 16px !important;
            }
          }

          .order-beverages {
            .beverage-item {
              .number-control {
                flex: 1 1 10%;

                .button-icon {
                  cursor: pointer;
                  font-size: 12px;
                }

                .button-number {
                  font-size: 10px !important;
                }
              }

              .description {
                flex: 1 1 65%;
                display: flex;
                padding-left: 10px;
                flex-direction: column;
                justify-content: center;

                label {
                  margin: 0;
                  font-size: 14px !important;
                }
              }

              .price {
                flex: 1 1 25%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                label {
                  font-size: 18px !important;
                }

                button {
                  padding: 2px;
                  font-size: 10px;
                }
              }
            }

            .beverage-no-item {
              h6 {
                margin: 10px !important;
                font-size: 14px !important;
              }

              label {
                margin: 0;
                font-size: 16px;
                cursor: pointer;
              }
            }
          }
        }

        .order-props {
          .delivery-tax {
            font-size: 16px !important;
          }

          .coupon {
            font-size: 16px !important;

            input {
              font-size: 14px !important;
            }
          }
        }

        .order-total {
          font-size: 18px !important;

          span {
            font-size: 16px !important;
          }
        }

        .order-buttons {
          label {
            padding: 10px 40px !important;
            font-size: 14px !important;
          }
        }
      }
    }

    .my-orders {
      bottom: 2vh !important;
      right: unset !important;
      left: 2vw !important;
      top: unset !important;
    }
  }

  .section-main {
    .menu-options,
    .menu-options-fixed {
      label {
        padding: 10px !important;
        font-size: 16px !important;
      }
    }

    .main-options {
      width: 100%;

      .main-option {
        // padding: 0 40px !important;
        align-items: center !important;
        justify-content: center !important;

        .option-title {
          text-align: center !important;
          font-size: 18px !important;
          text-transform: uppercase;
        }

        .option-subtitle {
          font-weight: bold;
          font-size: 16px;
          color: #000000;
          text-align: center !important;
        }

        .color-store {
          color: $button-color-default;
        }
      }

      .separator-option {
        margin-top: 20px !important;
      }
    }
  }

  .section-combos {
    .combos-content {
      .card-combo {
        flex: 0 1 calc(33.33% - 22px);

        .card-img {
          width: 180px;
          height: 180px;
        }
      }
    }
  }

  .section-products {
    .content-products {
      .card-product {
        flex: 0 1 calc(33.33% - 22px);

        .card-img {
          width: 180px !important;
          height: 180px !important;
        }
      }
    }
  }

  .section-beverages {
    .content-beverages {
      .card-category {
        flex: 0 1 calc(16.66% - 22px);

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-img-top {
          max-height: 140px !important;
        }
      }

      .card-beverage {
        flex: 0 1 calc(14.28% - 22px);

        .card-img-top {
          max-height: 170px !important;
        }
      }
    }
  }

  .builder {
    .builder--body {
      // min-height: 500px !important;
      // max-height: 600px !important;
    }

    .left-side-builder {
      .card-size {
        // flex: 0 1 calc(25% - 22px);

        .card-body {
          padding: 10px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 100px !important;
          height: 90px !important;
        }

        .card-description {
          margin: 0 !important;
        }
      }

      .card-flavour {
        flex: 0 1 calc(33.33% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 80px !important;
          height: 80px !important;
        }
      }

      .card-ingredient {
        flex: 0 1 calc(20% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }
        }
      }

      .card-additional {
        flex: 0 1 calc(25% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }
      }

      .card-optional {
        flex: 0 1 calc(25% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }
      }

      .card-beverage {
        flex: 0 1 calc(33.33% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 80px !important;
          height: 80px !important;
        }
      }

      .amount-product-flavour {
        .title {
          font-size: 18px !important;
        }

        .button-group {
          label {
            font-size: 40px !important;
          }

          svg {
            font-size: 40px !important;
          }
        }
      }
    }

    .right-side-builder {
      width: 50% !important;

      .card {
        height: 100% !important;

        .card-img-top {
          max-height: 40vh;
        }

        .card-body {
          .card-info {
            // margin-top: 0 !important;
            // border-top-left-radius: 0 !important;
            // border-top-right-radius: 0 !important;
          }
        }

        .card-amount-flavour {
          display: flex !important;
          flex-direction: column !important;
          // padding: 8px !important;
          width: 100% !important;

          .card-image-top {
            display: flex !important;
            height: 25vh !important;
          }

          .card-info {
            width: 100% !important;
            padding: 8px;

            .card-title {
              margin: 6px 0 !important;
              font-weight: 600 !important;
            }

            .card-description {
              margin: 10px 0 !important;
              font-size: 14px !important;
              overflow-y: auto !important;
              max-height: 100px !important;
              font-weight: 300 !important;
            }

            .card-price {
              margin: 6px 0 !important;
            }
          }
        }
      }
    }
  }

  .modal-flavours {
    .card-flavours {
      flex: 0 1 calc(50% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 100px !important;
        height: 90px !important;
      }
    }
  }

  .modal-ingredients {
    .card-ingredient {
      flex: 0 1 calc(20% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 60px !important;
        }
      }
    }

    .card-optional {
      flex: 0 1 calc(25% - 22px);

      .card-body {
        padding: 8px !important;
        flex-direction: row !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 60px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }
    }
  }

  .modal-beverages {
    .card-beverage {
      flex: 0 1 calc(33.33% - 22px);

      .card-body {
        padding: 4px 8px !important;

        .card-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100px;
          font-size: 14px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 90px !important;
        height: 90px !important;
      }
    }
  }

  .modal-payment-methods {
    .method {
      flex: 0 1 calc(33.33% - 22px);
    }
  }
}

// 1600px
@media #{$max-desktop-md} {
  .section-home {
    padding: 0 25% !important;
  }

  .section-header {
    height: 300px !important;
  }

  .section-content {
    padding: 0 !important;
    border-radius: 0 !important;

    .left-side-content {
      width: 75% !important;
    }

    .right-side-content,
    .right-side-content-fixed {
      width: 25% !important;

      .order-preview {
        .order-title {
          font-size: 16px !important;
        }

        .order-content {
          max-height: 370px !important;

          .order-products {
            .product-item {
              padding: 10px;

              .number-control {
                .button-icon {
                  font-size: 12px;
                }

                .button-number {
                  margin: 0;
                  font-size: 12px;
                  font-weight: 600;
                  padding: 4px 10px;
                }
              }

              .product-description {
                padding-left: 10px;

                label {
                  margin: 0;
                }
              }

              .product-price {
                label {
                  font-size: 14px;
                }

                button {
                  padding: 2px;
                  font-size: 10px;
                }
              }
            }

            h5 {
              margin: 10px !important;
              font-size: 16px !important;
            }
          }

          .order-beverages {
            .beverage-item {
              .number-control {
                flex: 1 1 10%;

                .button-icon {
                  cursor: pointer;
                  font-size: 12px;
                }

                .button-number {
                  font-size: 10px !important;
                }
              }

              .description {
                flex: 1 1 65%;
                display: flex;
                padding-left: 10px;
                flex-direction: column;
                justify-content: center;

                label {
                  margin: 0;
                  font-size: 14px !important;
                }
              }

              .price {
                flex: 1 1 25%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                label {
                  font-size: 14px !important;
                }

                button {
                  padding: 2px;
                  font-size: 10px;
                }
              }
            }

            .beverage-no-item {
              h6 {
                margin: 10px !important;
                font-size: 14px !important;
              }

              label {
                margin: 0;
                font-size: 16px;
                cursor: pointer;
              }
            }
          }
        }

        .order-props {
          .delivery-tax {
            font-size: 16px !important;
          }

          .coupon {
            font-size: 16px !important;

            input {
              font-size: 14px !important;
            }
          }
        }

        .order-total {
          font-size: 18px !important;

          span {
            font-size: 16px !important;
          }
        }

        .order-buttons {
          label {
            font-size: 14px !important;
          }
        }
      }

      .order-preview-no-items {
        padding-top: 100% !important;
        font-size: 16px !important;
        justify-content: unset !important;
      }
    }

    .fab-container {
      bottom: 0 !important;
      right: unset !important;
      left: 2vw !important;
      top: unset !important;
    }
  }

  .section-order-preview {
    width: 50vw !important;
  }

  .section-footer {
    width: 50vw !important;
  }

  .section-main {
    .menu-options,
    .menu-options-fixed {
      padding: 0 !important;
      width: 50vw !important;
      height: 50px !important;

      .menu-options-items {
        height: 50px !important;
        width: 50vw !important;
        padding: 0 !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
      }

      label {
        padding: 10px !important;
        font-size: 16px !important;
      }
    }

    .main-options {
      width: 100%;

      .main-option {
        // padding: 0 40px !important;
        align-items: center !important;
        justify-content: center !important;

        .option-title {
          text-align: center !important;
          font-size: 18px !important;
          text-transform: uppercase;
        }

        .option-subtitle {
          font-weight: bold;
          font-size: 16px;
          color: #000000;
          text-align: center !important;
        }

        .color-store {
          color: $button-color-default;
        }
      }

      .separator-option {
        margin-top: 20px !important;
      }
    }
  }

  .section-combos {
    .combos-content {
      .card-combo {
        flex: 0 1 calc(50% - 22px);

        .card-body {
          // padding: 8px 12px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            max-height: 55px !important;
            font-size: 12px !important;
            margin: 0 !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        // .card-img {
        //   width: 140px !important;
        //   height: 140px !important;
        // }
      }
    }
  }

  .section-products {
    .content-products {
      .product-title {
        padding: 10px !important;
      }

      .product-content {
        margin: 0 !important;
        align-items: center !important;
        justify-content: flex-start !important;

        .card-product {
          margin: 0 !important;
          flex: 0 1 100% !important;
        }
      }
    }
  }

  .section-beverages {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;

    .beverage-content {
      margin: 0 !important;
      align-items: center !important;
      justify-content: flex-start !important;

      .card-beverage {
        margin: 0 !important;
        flex: 0 1 100% !important;
      }

      .card-category {
        margin: 0 !important;
        flex: 0 1 100% !important;

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-img-top {
          max-height: 120px !important;
        }
      }
    }
  }

  .builder {
    .builder--body {
      // max-height: 450px !important;
    }

    .left-side-builder {
      .card-size {
        // flex: 0 1 calc(25% - 22px);

        .card-body {
          padding: 10px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 100px !important;
          height: 90px !important;
        }

        .card-description {
          margin: 0 !important;
        }
      }

      .card-flavour {
        flex: 0 1 calc(33.33% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 80px !important;
          height: 80px !important;
        }
      }

      .card-ingredient {
        flex: 0 1 calc(20% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }
        }
      }

      .card-additional {
        flex: 0 1 calc(25% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }
      }

      .card-optional {
        flex: 0 1 calc(25% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }
      }

      .card-beverage {
        flex: 0 1 calc(33.33% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 80px !important;
          height: 80px !important;
        }
      }

      .builder-graph {
        .flavours-graph {
          margin-top: 0 !important;

          .lbl-flavour-numbers {
            font-size: 14px !important;
          }

          .lbl-no-flavours {
            font-size: 14px !important;
          }
        }

        .lbl-info-ingredients {
          font-size: 14px !important;
        }
      }
    }

    .right-side-builder {
      width: 50% !important;

      .card {
        height: 100% !important;

        .card-body {
          .card-info {
            // margin-top: 0 !important;
            // border-top-left-radius: 0 !important;
            // border-top-right-radius: 0 !important;
          }
        }

        .card-img-top {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .modal-flavours {
    .card-flavours {
      flex: 0 1 calc(50% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 100px !important;
        height: 90px !important;
      }
    }
  }

  .modal-beverages {
    .card-beverage {
      flex: 0 1 calc(33.33% - 22px);

      .card-body {
        padding: 4px 8px !important;

        .card-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100px;
          font-size: 14px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 90px !important;
        height: 90px !important;
      }
    }
  }

  .modal-payment-methods {
    .method {
      flex: 0 1 calc(33.33% - 22px);
    }
  }

  .modal-payment-change {
    .change,
    .change-input {
      flex: 0 1 calc(33.33% - 22px);
    }
  }
}

// 1280px
@media #{$max-desktop-sm} {
  .section-home {
    padding: 0 25% !important;
  }

  .section-header {
    height: 300px !important;
  }

  .section-content {
    padding: 0 !important;
    border-radius: 0 !important;
  }

  .section-footer {
    width: 50vw !important;
  }

  .section-main {
    .menu-options,
    .menu-options-fixed {
      padding: 0 !important;
      width: 50vw !important;
      height: 50px !important;

      .menu-options-items {
        height: 50px !important;
        width: 50vw !important;
        padding: 0 !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
      }

      label {
        padding: 10px !important;
        font-size: 16px !important;
      }
    }

    .main-options {
      width: 100%;

      .main-option {
        // padding: 0 40px !important;
        align-items: center !important;
        justify-content: center !important;

        .option-title {
          text-align: center !important;
          font-size: 18px !important;
          text-transform: uppercase;
        }

        .option-subtitle {
          font-weight: bold;
          font-size: 16px;
          color: #000000;
          text-align: center !important;
        }

        .color-store {
          color: $button-color-default;
        }
      }

      .separator-option {
        margin-top: 20px !important;
      }
    }
  }

  .section-combos {
    .combos-content {
      .card-combo {
        flex: 0 1 calc(50% - 22px);

        .card-img {
          width: 200px;
          height: 180px;
        }
      }
    }
  }

  .section-products {
    .content-products {
      .product-title {
        padding: 10px !important;
      }

      .product-content {
        margin: 0 !important;
        align-items: center !important;
        justify-content: flex-start !important;

        .card-product {
          margin: 0 !important;
          flex: 0 1 100% !important;
        }
      }
    }
  }

  .section-beverages {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;

    .beverage-content {
      margin: 0 !important;
      align-items: center !important;
      justify-content: flex-start !important;

      .card-beverage {
        margin: 0 !important;
        flex: 0 1 100% !important;
      }

      .card-category {
        margin: 0 !important;
        flex: 0 1 100% !important;

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-img-top {
          max-height: 120px !important;
        }
      }
    }
  }

  .modal-flavours {
    .flavours-card {
      flex: 0 1 calc(50% - 22px);
    }
  }

  .modal-additional {
    .modal-body {
      .collapse-item {
        .collapse-content {
          flex-direction: column;

          .card-additional {
            width: 100% !important;
          }
        }
      }
    }
  }

  .builder {
    .builder--body {
      // max-height: 600px !important;

      .right-side-builder {
        width: 50% !important;

        .card {
          .card-img {
            // max-height: 265px;
          }

          .card-body {
            .card-info {
              // margin-top: 0 !important;
              // border-top-left-radius: 0 !important;
              // border-top-right-radius: 0 !important;
            }
          }

          .card-amount-flavour {
            display: flex !important;
            flex-direction: column !important;
            // padding: 8px !important;
            width: 100% !important;

            .card-image-top {
              display: flex !important;
              height: 30vh !important;
            }

            .card-info {
              width: 100% !important;
              padding: 8px;

              .card-title {
                margin: 6px 0 !important;
                font-weight: 600 !important;
              }

              .card-description {
                margin: 10px 0 !important;
                font-size: 14px !important;
                overflow-y: auto !important;
                max-height: 100px !important;
                font-weight: 300 !important;
              }

              .card-price {
                margin: 6px 0 !important;
              }
            }
          }
        }
      }
    }
  }
}

//Resoluções para tablets
// 1024px
@media #{$max-tablet-lg} {
  .section-header {
    height: 320px;
  }

  //.section-order-preview{
  //  width: 100% !important;
  //}

  .section-footer {
    margin-bottom: 58px !important;
    //width: 100% !important;
  }

  .section-content {
    .left-side-content {
      width: 100% !important;
    }

    .right-side-content,
    .right-side-content-fixed {
      display: none !important;
    }

    .my-orders {
      display: none !important;
    }
  }

  .section-main {
    .menu-options,
    .menu-options-fixed {
      width: 100% !important;
      justify-content: center !important;

      label {
        padding: 10px !important;
        font-size: 16px !important;
      }
    }

    .main-options {
      width: 100%;
      flex-direction: column-reverse;

      .main-option {
        // padding: 0 40px !important;
        align-items: center !important;
        justify-content: center !important;

        .option-title {
          text-align: center !important;
          font-size: 18px !important;
          text-transform: uppercase;
        }

        .option-subtitle {
          font-weight: bold;
          font-size: 16px;
          color: #000000;
          text-align: center !important;
        }

        .color-store {
          color: $button-color-default;
        }

        .option-title-main-closed {
          display: none !important;
        }
      }

      .separator-option {
        margin-top: 20px !important;
      }
    }
  }

  .section-combos {
    .combos-title {
      // padding: 0 20px !important;

      // label {
      //   font-size: 20px !important;
      // }

      span {
        font-size: 14px !important;
      }
    }

    .combos-content {
      // padding: 0 10px !important;

      .card-combo {
        flex: 0 1 calc(50% - 22px);

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        // .card-img {
        //   width: 140px !important;
        //   max-height: 140px !important;
        // }
      }
    }
  }

  .section-products {
    .product-title {
      // padding: 0 20px !important;

      // label {
      //   font-size: 20px !important;
      // }

      span {
        font-size: 14px !important;
      }
    }

    .content-products {
      .product {
        // padding: 20px 10px 0 10px !important;
      }

      .card-product {
        flex: 0 1 calc(50% - 22px);

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        // .card-img {
        //   width: 150px !important;
        //   max-height: 140px !important;
        // }
      }
    }
  }

  .section-beverages {
    .title-beverages {
      padding: 0 20px !important;

      label {
        font-size: 20px !important;
      }

      span {
        font-size: 14px !important;
      }
    }

    .content-beverages {
      // padding: 0 10px !important;

      .card-category {
        flex: 0 1 calc(33.33% - 22px) !important;

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }
      }

      .card-beverage {
        flex: 0 1 calc(33.33% - 22px) !important;

        .card-body {
          padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }
    }
  }

  .section-footer {
    .info-footer {
      padding: 10px !important;
      align-items: center !important;
      flex-direction: column !important;

      .contacts {
        width: 100% !important;

        .buttons {
          label {
            font-size: 12px !important;
          }
        }
      }

      .payments {
        width: 50% !important;

        .methods {
          align-items: center !important;
          justify-content: center !important;

          .method {
            flex: 0 1 calc(20% - 22px) !important;

            img {
              width: 70px !important;
              height: 40px !important;
            }

            label {
              display: none;
            }
          }
        }
      }
    }
  }

  .builder {
    .builder--body {
      flex-flow: row !important;
      // max-height: 500px !important;
    }

    .close-button-modal-builder {
      // top: 0;
      // bottom: 5px;
    }

    .left-side-builder {
      width: 100% !important;

      .card-size {
        // flex: 0 1 calc(25% - 22px);

        .card-body {
          padding: 10px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 100px !important;
          height: 90px !important;
        }

        .card-description {
          margin: 0 !important;
        }
      }

      .card-flavour {
        flex: 0 1 calc(100% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 80px !important;
          height: 80px !important;
        }
      }

      .card-ingredient {
        flex: 0 1 calc(50% - 22px);

        .card-body {
          padding: 8px !important;
          justify-content: center;
          align-items: center;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }
        }
      }

      .card-additional {
        flex: 0 1 calc(50% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }
      }

      .card-optional {
        flex: 0 1 calc(50% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-description {
            font-size: 12px !important;
            margin: 0 !important;
            max-height: 60px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }
      }

      .card-beverage {
        flex: 0 1 calc(33.33% - 22px);

        .card-body {
          padding: 8px !important;

          .card-title {
            font-size: 14px !important;
          }

          .card-price {
            font-size: 14px !important;
          }
        }

        .card-img {
          width: 80px !important;
          height: 80px !important;
        }
      }
    }

    .right-side-builder {
      width: 50% !important;

      .card {
        height: 100% !important;

        .card-img-top {
          // display: none !important;
          width: 100%;
          height: auto;
        }

        .card-body {
          .card-info {
            // margin-top: 0 !important;
            // border-top-left-radius: 0 !important;
            // border-top-right-radius: 0 !important;
          }
        }

        .card-amount-flavour {
          display: flex !important;
          flex-direction: column !important;
          // padding: 8px !important;
          width: 100% !important;

          .card-image-top {
            display: flex !important;
          }

          .card-info {
            width: 100% !important;
            padding: 8px;

            .card-title {
              margin: 6px 0 !important;
              font-weight: 600 !important;
            }

            .card-description {
              margin: 10px 0 !important;
              font-size: 14px !important;
              overflow-y: auto !important;
              max-height: 100px !important;
              font-weight: 300 !important;
            }

            .card-price {
              margin: 6px 0 !important;
            }
          }
        }
      }
    }

    .center-builder {
      .card {
        flex-direction: column !important;

        .card-img-top {
          width: 40% !important;
          max-height: 170px !important;
          display: block !important;
        }

        .card-body {
          width: 100% !important;
        }
      }
    }
  }

  .modal-flavours {
    .card-flavours {
      flex: 0 1 calc(100% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 40px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 100px !important;
        height: 100px !important;
      }
    }
  }

  .modal-ingredients {
    .card-ingredient {
      flex: 0 1 calc(50% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 60px !important;
        }
      }
    }

    .card-optional {
      flex: 0 1 calc(100% - 22px);

      .card-body {
        padding: 8px !important;
        flex-direction: row !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 60px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }
    }
  }

  .modal-beverages {
    .card-beverage {
      flex: 0 1 calc(100% - 22px);

      .card-body {
        padding: 4px 8px !important;

        .card-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 200px;
          font-size: 14px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 90px !important;
        height: 90px !important;
        background-color: #e5e5e5 !important;
      }
    }
  }

  .modal-companies {
    .location-methods {
      flex-direction: column !important;

      .left-side {
        width: 100% !important;
      }

      .center {
        width: 100% !important;
      }

      .right-side {
        width: 100% !important;
      }

      .separator {
        text-align: center;
      }
    }
  }

  .modal-payment-methods {
    .icon-map {
      width: unset !important;
    }

    .description {
      width: unset !important;
    }

    .change-button {
      width: unset !important;
    }

    .method {
      flex: 0 1 calc(100% - 22px);
    }
  }

  .modal-payment-change {
    .change,
    .change-input {
      flex: 0 1 calc(50% - 22px);
    }
  }

  .modal-schedule {
    .modal-body {
      // height: 70vh;
      // overflow: scroll;
    }

    .modal-footer {
      .modal-footer .warn-description {
        font-size: 14px;
      }
    }
  }

  .modal-footer {
    .footer-buttons {
      width: 40% !important;
      margin-right: 5px !important;

      .footer-button-right {
        font-size: 12.5px !important;
      }
    }
  }
}

// 900px
@media #{$max-tablet-md} {
  .section-header {
    height: 260px !important;

    .header-main-options-container {
      display: none;
    }

    .top-side {
      .left-side {
        display: none !important;
      }
    }
  }

  .section-footer {
    width: 100vw;
  }

  .section-main {
    .menu-options,
    .menu-options-fixed {
      width: 100% !important;
      justify-content: center !important;

      label {
        padding: 12px !important;
        font-size: 16px !important;
      }

      .category-selector {
        top: 100px !important;
      }
    }

    .main-options {
      width: 100%;
      display: flex;

      .main-option {
        // padding: 0 20px !important;
        align-items: center !important;
        justify-content: center !important;

        .option-title {
          text-align: center !important;
          font-size: 14px !important;
          text-transform: uppercase;
        }

        .option-subtitle {
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          text-align: center !important;
          font-family: "Rubik", serif !important;
        }

        .option-badge {
          font-size: 10px;
        }

        .color-store {
          color: $button-color-default;
        }
      }

      .separator-option {
        margin-top: 20px !important;
      }
    }
  }

  // .section-highlights {
  //   .title-highlight {
  //     padding: 0 20px !important;

  //     label {
  //       font-size: 20px !important;
  //     }

  //     span {
  //       font-size: 14px !important;
  //     }
  //   }

  //   .content-highlight {
  //     padding: 0 !important;

  //     .slide-previous {
  //       display: none !important;
  //     }

  //     .slide-next {
  //       display: none !important;
  //     }

  //     .cards-highlight {
  //       padding: 10px 10px 30px 10px !important;

  //       .card-highlight {
  //         flex: 0 1 calc(33.33% - 22px);

  //         .card-body {
  //           padding: 8px !important;

  //           .card-title {
  //             font-size: 12px !important;
  //             margin-bottom: 4px !important;
  //           }

  //           p {
  //             font-size: 12px !important;
  //             margin-bottom: 4px !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .section-combos {
    .combos-title {
      // padding: 0 20px !important;

      // label {
      //   font-size: 20px !important;
      // }

      span {
        font-size: 14px !important;
      }
    }

    .combos-content {
      // padding: 0 10px !important;

      .card-combo {
        flex: 0 1 calc(50% - 22px);

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        // .card-img {
        //   width: 150px !important;
        //   max-height: 140px !important;
        // }
      }
    }
  }

  .section-products {
    .product-title {
      // padding: 0 20px !important;

      // label {
      //   font-size: 20px !important;
      // }

      span {
        font-size: 14px !important;
      }
    }

    .content-products {
      .product {
        // padding: 20px 10px 0 10px !important;
      }

      .card-product {
        flex: 0 1 calc(50% - 22px);

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        // .card-img {
        //   width: 150px !important;
        //   height: 150px !important;
        // }
      }
    }
  }

  .section-beverages {
    .title-beverages {
      padding: 0 20px !important;

      label {
        font-size: 20px !important;
      }

      span {
        font-size: 14px !important;
      }
    }

    .beverage-content {
      // padding: 0 10px !important;

      .card-category {
        flex: 0 1 calc(50% - 22px) !important;

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-img-top {
          max-height: 120px !important;
        }
      }

      .card-beverage {
        flex: 0 1 calc(50% - 22px) !important;

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        .card-img-top {
          max-height: 140px !important;
        }
      }
    }
  }

  .section-footer {
    .info-footer {
      padding: 10px !important;
      align-items: center !important;
      flex-direction: column !important;

      .contacts {
        width: 100% !important;

        .buttons {
          label {
            font-size: 12px !important;
          }
        }
      }

      .payments {
        width: 50% !important;

        .methods {
          align-items: center !important;
          justify-content: center !important;

          .method {
            flex: 0 1 calc(20% - 22px) !important;

            img {
              width: 70px !important;
              height: 40px !important;
            }

            label {
              display: none;
            }
          }
        }
      }
    }
  }

  .builder {
    .builder--body {
      flex-flow: column-reverse !important;
      // max-height: 700px !important;

      .right-side-builder {
        width: 100% !important;

        .card {
          //*** height: 100% !important;

          .card-body {
            .card-info {
              // margin-top: 0 !important;
              // border-top-left-radius: 0 !important;
              // border-top-right-radius: 0 !important;
            }
          }

          .card-img-top {
            display: none !important;
            width: 100%;
            // height: 350px;
          }
        }
      }
    }
  }
}

// 768px
@media #{$max-tablet-sm} {
  .section-header {
    height: 240px !important;

    .top-side {
      .left-side {
        .img-logo {
          height: 150px;
          max-width: 250px;
        }
      }
    }
  }

  .section-content {
    .left-side-content {
      width: 100% !important;
    }

    .right-side-content,
    .right-side-content-fixed {
      display: none !important;
    }

    .my-orders {
      display: none !important;
    }
  }

  .section-main {
    .menu-options,
    .menu-options-fixed {
      width: 100% !important;
      justify-content: center !important;

      label {
        padding: 12px !important;
        font-size: 16px !important;
      }
    }

    .main-options {
      width: 100%;

      .main-option {
        // padding: 0 30px !important;
        align-items: center !important;
        justify-content: center !important;

        .option-title {
          text-align: center !important;
          font-size: 12px !important;
          text-transform: uppercase;
        }

        .option-subtitle {
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          text-align: center !important;
        }

        .option-badge {
          font-size: 10px;
        }

        .color-store {
          color: $button-color-default;
        }
      }

      .separator-option {
        margin-top: 20px !important;
      }

      .main-option--company-name {
        top: -45px;
        max-width: 200px;
      }

      .main-option--status-company {
        top: -45px;
        width: 200px;

        label {
          font-size: 12px;
          padding: 4px;
        }
      }
    }
  }

  .section-combos {
    .combos-title {
      // padding: 0 20px !important;

      // label {
      //   font-size: 20px !important;
      // }

      span {
        font-size: 14px !important;
      }
    }

    .combos-content {
      // padding: 0 10px !important;

      .card-combo {
        flex: 0 1 100%;
        // flex: 0 1 calc(50% - 22px);

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        // .card-img {
        //   width: 130px !important;
        //   height: 130px !important;
        // }
      }
    }
  }

  .section-products {
    .product-title {
      // padding: 0 20px !important;

      // label {
      //   font-size: 20px !important;
      // }

      span {
        font-size: 14px !important;
      }
    }

    .content-products {
      .product {
        // padding: 20px 10px 0 10px !important;
        .product-content {
          .card-product {
            flex: 0 1 100%;
            // flex: 0 1 calc(50% - 22px);

            .card-body {
              // padding: 10px !important;
            }

            .card-title {
              margin: 0 !important;
              font-size: 14px !important;
            }

            .card-description {
              margin: 10px 0 !important;
              font-size: 12px !important;
              max-height: 50px !important;
            }

            .card-price {
              font-size: 14px !important;
            }

            // .card-img {
            //   width: 130px !important;
            //   height: 130px !important;
            // }
          }
        }
      }
    }
  }

  .section-beverages {
    .title-beverages {
      // padding: 0 20px !important;

      label {
        font-size: 20px !important;
      }

      span {
        font-size: 14px !important;
      }
    }

    .beverage-content {
      // padding: 0 10px !important;

      .card-category {
        flex: 0 1 100%;
        // flex: 0 1 calc(25% - 22px);

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        // .card-img-top {
        //   max-height: 120px !important;
        // }
      }

      .card-beverage {
        flex: 0 1 100%;
        // flex: 0 1 calc(25% - 22px);

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        // .card-img-top {
        //   max-height: 140px !important;
        // }
      }
    }
  }

  .section-order-preview {
    //width: 100% !important;
  }

  .section-footer {
    //width: 100% !important;

    .info-footer {
      padding: 10px !important;
      align-items: center !important;
      flex-direction: column !important;

      .contacts {
        width: 100% !important;

        .buttons {
          label {
            font-size: 12px !important;
          }
        }
      }

      .payments {
        width: 80% !important;

        .methods {
          align-items: center !important;
          justify-content: center !important;

          .method {
            flex: 0 1 calc(20% - 22px) !important;

            img {
              width: 70px !important;
              height: 40px !important;
            }

            label {
              display: none;
            }
          }
        }
      }
    }
  }

  .modal-companies {
    .location-methods {
      flex-direction: column !important;

      .left-side {
        width: 100% !important;
      }

      .center {
        width: 100% !important;
      }

      .right-side {
        width: 100% !important;
      }

      .separator {
        text-align: center;
      }
    }
  }

  .builder {
    .modal-content {
      // max-height: 800px !important;

      .builder--body {
        flex-flow: column-reverse !important;
        // max-height: 700px !important;
      }

      .left-side-builder {
        width: 100% !important;

        .card-size {
          // flex: 0 1 calc(25% - 22px);

          .card-body {
            padding: 10px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }

          .card-img {
            width: 100px !important;
            height: 90px !important;
          }

          .card-description {
            margin: 0 !important;
          }
        }

        .card-flavour {
          flex: 0 1 calc(100% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }

          .card-img {
            width: 80px !important;
            height: 80px !important;
          }
        }

        .card-ingredient {
          flex: 0 1 calc(50% - 22px);

          .card-body {
            padding: 8px !important;
            justify-content: center;
            align-items: center;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }
          }
        }

        .card-additional {
          flex: 0 1 calc(50% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }
        }

        .card-optional {
          flex: 0 1 calc(50% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }
        }

        .card-beverage {
          flex: 0 1 calc(50% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }

          .card-img {
            width: 80px !important;
            height: 80px !important;
          }
        }

        .amount-product-flavour {
          .title {
            font-size: 18px !important;
          }

          .button-group {
            label {
              font-size: 40px !important;
            }

            svg {
              font-size: 40px !important;
            }
          }
        }
      }

      .right-side-builder {
        width: 100% !important;

        .card {
          height: 100% !important;

          .card-body {
            .card-info {
              // margin-top: 0 !important;
              // border-top-left-radius: 0 !important;
              // border-top-right-radius: 0 !important;
            }
          }

          .card-img-top {
            display: none !important;
            width: 100%;
            height: 350px;
          }

          .card-amount-flavour {
            display: flex !important;
            flex-direction: column !important;
            // padding: 8px !important;
            width: 100% !important;

            .card-image-top {
              display: flex !important;
            }

            .card-info {
              width: 100% !important;
              padding: 8px;

              .card-title {
                margin: 6px 0 !important;
                font-weight: 600 !important;
              }

              .card-description {
                margin: 0 !important;
                font-size: 14px !important;
                overflow-y: auto !important;
                max-height: 100px !important;
                font-weight: 300 !important;
              }

              .card-price {
                margin: 6px 0 !important;
              }
            }
          }
        }
      }

      .center-builder {
        .card {
          flex-direction: column !important;

          .card-img-top {
            width: 50% !important;
            display: block !important;
          }

          .card-body {
            width: 100% !important;
          }
        }
      }
    }
  }

  .modal-flavours {
    .modal-body {
      // max-height: 900px !important;
    }

    .card-flavours {
      flex: 0 1 calc(100% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 40px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 100px !important;
        height: 100px !important;
      }
    }
  }

  .modal-beverages {
    .card-category {
      label {
        width: 40% !important;
      }
    }
  }

  .modal-footer {
    justify-content: center !important;

    .footer-buttons {
      width: 96% !important;

      .footer-button-right {
        font-size: 14px !important;
      }
    }
  }
}

//Resoluções para celulares
// 600px
@media #{$max-mobile-lg} {
  .section-home {
    padding: 0 !important;
  }

  .section-navbar {
    .bm-burger-button {
      display: inline !important;
    }
  }

  .section-header {
    height: 180px !important;

    .top-side {
      .left-side {
        display: none !important;
      }

      .center {
        display: flex !important;

        .img-center {
          display: flex !important;
        }

        .img-logo {
          // height: 120px;
          max-width: 200px;
        }
      }

      .right-side {
        display: none !important;
      }
    }

    .bottom-side {
      .left-side {
        display: none !important;
      }

      .center {
        display: none !important;
      }

      .right-side {
        display: none !important;
        margin: 0 !important;
        border-radius: unset !important;
        align-items: center !important;
        justify-content: space-around !important;
      }

      .main-option {
        .option-title {
          font-size: 12px !important;
          text-transform: uppercase;
        }

        .option-subtitle {
          font-size: 10px !important;
        }
      }

      .separator-option {
        width: 1px;
        height: 80px;
        background-color: #6b6b6b;
      }
    }
  }

  .section-content {
    padding: 0;

    .left-side-content {
      width: 100% !important;

      .section-main {
        .main-options--modality-popup {
          // top: 80px;
        }
      }
    }

    .right-side-content,
    .right-side-content-fixed {
      display: none !important;
    }

    .my-orders {
      display: none !important;
    }
  }

  .section-main {
    .menu-options,
    .menu-options-fixed {
      justify-content: flex-start !important;

      label {
        padding: 8px !important;
        font-size: 14px !important;
      }
    }

    .main-options {
      width: 100%;

      .main-option {
        // padding: 0 20px !important;
        align-items: center !important;
        justify-content: center !important;

        .option-title {
          text-align: center !important;
          font-size: 12px !important;
          text-transform: uppercase;
        }

        .option-subtitle {
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          text-align: center !important;
          font-family: "Rubik", serif !important;
        }

        .option-title-main {
          font-size: 9px !important;
        }

        .option-badge {
          font-size: 10px;
        }

        .color-store {
          color: $button-color-default;
        }
      }

      .separator-option {
        margin-top: 20px !important;
      }
    }
  }

  .section-combos {
    .content-combos {
      padding: 0 !important;
    }

    .combos-title {
      // padding: 0 20px !important;

      // label {
      //   font-size: 16px !important;
      // }

      span {
        font-size: 12px !important;
      }
    }

    .combos-content {
      padding: 0 !important;

      .card-combo {
        flex: 0 1 100% !important;

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        .card-img {
          width: 150px !important;
          height: 150px !important;
          // max-height: 150px !important;
        }
      }
    }
  }

  .section-products {
    .product-title {
      span {
        font-size: 12px !important;
      }
    }

    .content-products {
      .product {
        // padding: 20px 0 0 0 !important;

        .product-content {
          .card-product {
            flex: 0 1 100%;
            margin-bottom: 0 !important;
            margin-right: 0 !important;

            .card-body {
              // padding: 10px !important;
            }

            .card-title {
              margin: 0 !important;
              font-size: 14px !important;
            }

            .card-description {
              margin: 10px 0 !important;
              font-size: 12px !important;
              max-height: 50px !important;
            }

            .card-price {
              font-size: 14px !important;
            }

            .card-img {
              width: 150px !important;
              height: 150px !important;
            }
          }
        }
      }
    }
  }

  .section-beverages {
    padding: 0 !important;

    .title-beverages {
      padding: 0 20px !important;

      label {
        font-size: 16px !important;
      }

      span {
        font-size: 12px !important;
      }
    }

    .beverage-content {
      // padding: 0 10px !important;

      .card-category {
        margin-right: 0 !important;
        margin-bottom: 0 !important;
        flex: 0 1 calc(100%) !important;

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-img {
          width: 150px !important;
          height: 150px !important;
        }
      }

      .card-beverage {
        margin-right: 0 !important;
        margin-bottom: 0 !important;
        flex: 0 1 calc(100%) !important;

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        .card-img {
          width: 150px !important;
          height: 150px !important;
        }
      }
    }
  }

  .section-order-preview {
    width: 100% !important;
  }

  .section-footer {
    width: 100% !important;

    .info-footer {
      padding: 10px !important;
      flex-direction: column !important;

      .contacts {
        width: 100% !important;

        .buttons {
          flex-direction: column !important;

          .button {
            width: 100%;
          }

          label {
            font-size: 12px !important;
          }
        }
      }

      .payments {
        width: 100% !important;

        .methods {
          align-items: center !important;
          justify-content: center !important;

          .method {
            flex: 0 1 calc(20% - 22px) !important;

            img {
              width: 70px !important;
              height: 40px !important;
            }

            label {
              display: none;
            }
          }
        }
      }
    }
  }

  .modal-companies {
    .location-methods {
      flex-direction: column !important;

      .left-side {
        width: 100% !important;
      }

      .center {
        width: 100% !important;
      }

      .right-side {
        width: 100% !important;
      }

      .separator {
        text-align: center;
      }
    }
  }

  .builder {
    margin: 0 !important;
    height: 100vh !important;

    .modal-content {
      border-radius: unset !important;
      height: 100vh !important;
      width: 100vw !important;
      max-height: 100% !important;
    }

    .modal-header {
      color: #ffffff !important;
      padding: 10px !important;
      border-radius: unset !important;
      background-color: $button-color-default !important;

      h5 {
        visibility: hidden !important;

        &:before {
          content: "Voltar";
          font-size: 18px !important;
          font-weight: 600 !important;
          visibility: visible !important;
        }
      }

      button {
        display: none !important;
      }
    }

    .builder--body {
      flex-direction: column !important;
      flex-flow: column-reverse !important;
      // height: 100% !important;
      // max-height: 100% !important;
      justify-content: flex-end !important;
      // overflow: scroll;

      .left-side-builder {
        margin: 6px 0 0 0 !important;
        width: 100% !important;
        // height: 82% !important;

        .card-size {
          // flex: 0 1 calc(50% - 22px);

          .card-body {
            // padding: 10px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }

          .card-img {
            width: 100px !important;
            height: 90px !important;
          }

          .card-description {
            margin: 0 !important;
          }
        }

        .card-flavour {
          flex: 0 1 calc(100% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }

          .card-img {
            width: 80px !important;
            height: 80px !important;
          }
        }

        .card-ingredient {
          flex: 0 1 calc(50% - 22px);

          .card-body {
            padding: 8px !important;
            justify-content: center;
            align-items: center;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }
          }
        }

        .card-additional {
          flex: 0 1 calc(100% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }
        }

        .card-optional {
          flex: 0 1 calc(50% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }
        }

        .card-beverage {
          flex: 0 1 calc(100% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }

          .card-img {
            width: 80px !important;
            height: 80px !important;
          }
        }

        .amount-product-flavour {
          .title {
            font-size: 18px !important;
          }

          .button-group {
            label {
              font-size: 40px !important;
            }

            svg {
              font-size: 40px !important;
            }
          }
        }
      }

      .right-side-builder {
        margin: 0 !important;
        width: 100% !important;
        // height: 18% !important;

        .card {
          border: none !important;
          height: 100% !important;
          background-color: transparent !important;

          .card-img-top {
            display: none;
          }

          .card-body {
            display: flex !important;
            flex-direction: column-reverse !important;
            flex-wrap: nowrap !important;
            // padding: 8px !important;
            width: 100% !important;

            .card-info {
              // width: 65% !important;
              margin-top: -18px !important;
              border-top-left-radius: 20px !important;
              border-top-right-radius: 20px !important;
              padding: 8px;

              .card-title {
                margin-bottom: 6px !important;
              }

              .card-description {
                // margin: 0 !important;
                overflow-y: auto !important;
                // max-height: 80px !important;
              }
            }

            .card-image-right {
              width: 100% !important;
              display: flex !important;
            }
          }

          .card-amount-flavour {
            display: flex !important;
            flex-direction: column !important;
            // padding: 8px !important;
            width: 100% !important;

            .card-image-top {
              display: flex !important;
            }

            .card-info {
              width: 100% !important;
              padding: 8px;

              .card-title {
                margin: 6px 0 !important;
                font-weight: 600 !important;
              }

              .card-description {
                margin: 10px 0 !important;
                font-size: 14px !important;
                overflow-y: auto !important;
                max-height: 100px !important;
                font-weight: 300 !important;
              }

              .card-price {
                margin: 6px 0 !important;
              }
            }
          }
        }
      }

      .center-builder {
        height: 100% !important;
        margin-top: 16px;

        .card {
          flex-direction: column !important;

          .card-img-top {
            display: block !important;
          }

          .card-body {
            width: 100% !important;

            .card-info {
              width: 100% !important;
              padding: 8px;

              .card-price {
                display: inline !important;
              }
            }

            .card-image-right {
              display: none !important;
            }
          }
        }
      }
    }

    .modal-footer {
      padding: 0 !important;

      label {
        margin: 0 !important;
        width: 100% !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        padding: 16px 20px !important;
        text-align: center !important;
        border-radius: unset !important;
      }
    }
  }

  .modal-additional {
    margin: 0 !important;
    height: 100% !important;

    .modal-content {
      border-radius: unset !important;
      height: 100vh !important;
      width: 100vw !important;

      .modal-header {
        color: #ffffff !important;
        padding: 10px !important;
        border-radius: unset !important;
        background-color: $button-color-default !important;

        .modal-title {
          display: flex;
          align-items: center;

          .button-go-back-mobile {
            display: flex;
            margin-right: 16px;
          }
        }

        button {
          display: none !important;
        }
      }

      .modal-body {
        // height: 100% !important;
        // max-height: 100% !important;
        // overflow: scroll;

        .collapse-item {
          overflow: unset;

          .collapse-content {
            .collapse-card {
              margin: 16px 0;
            }
          }
        }
      }

      .modal-footer {
        margin: 0 !important;
        padding: 0 !important;
        justify-content: flex-end;

        .footer-button-left {
          display: none;
        }

        .footer-button-right {
          width: 100%;
          text-align: center;
          margin: 0 !important;
          border-radius: 0;
          font-size: 16px;
          padding: 16px 20px !important;
        }
      }
    }
  }

  .order-preview {
    .order-preview--body {
      padding: 0;
    }
  }

  .profile {
    .profile--user {
      .profile--user--body {
        padding: 0;
      }

      .personal-info {
        .personal-info--body {
          padding: 0;
        }
      }

      .addresses-info {
        .addresses-info--body {
          padding: 0;
        }
      }

      .orders-info {
        .orders-info--body {
          padding: 0;
        }
      }

      .order-progress {
        .order-progress--body {
          padding: 0;
        }
      }
    }
  }
}

// 480px
@media #{$max-mobile-md} {
  .section-home {
    padding: 0 !important;
  }

  .section-navbar {
    .bm-burger-button {
      display: inline !important;
    }
  }

  .section-header {
    height: 175px !important;

    .top-side {
      .left-side {
        display: none !important;
      }

      .center {
      }

      .right-side {
        display: none !important;
      }
    }

    .bottom-side {
      .left-side {
        display: none !important;
      }

      .center {
        display: none !important;
      }

      .right-side {
        display: none !important;
        margin: 0 !important;
        border-radius: unset !important;
        align-items: center !important;
        justify-content: space-around !important;
      }

      .separator-option {
        width: 1px;
        height: 80px;
        background-color: #6b6b6b;
      }
    }
  }

  .section-content {
    .left-side-content {
      width: 100% !important;
    }

    .right-side-content,
    .right-side-content-fixed {
      display: none !important;
    }

    .my-orders {
      display: none !important;
    }
  }

  .section-main {
    .menu-options,
    .menu-options-fixed {
      width: 100% !important;
      justify-content: flex-start !important;

      label {
        padding: 8px !important;
        font-size: 14px !important;
      }
    }

    .main-options {
      width: 100%;

      .main-option {
        // padding: 0 20px !important;
        align-items: center !important;
        justify-content: center !important;

        .option-badge {
          font-size: 10px;
        }

        .color-store {
          color: $button-color-default;
        }
      }

      .separator-option {
        margin-top: 20px !important;
      }

      .main-option--company-name {
        top: -50px;
        max-width: 120px;
      }

      .main-option--status-company {
        top: -50px;
        width: 120px;

        label {
          font-size: 10px;
          padding: 2px 4px;
        }
      }
    }
  }

  .section-combos {
    .combos-title {
      // padding: 0 10px !important;

      // label {
      //   font-size: 16px !important;
      // }

      span {
        font-size: 12px !important;
      }
    }

    .combos-content {
      // padding: 0 10px !important;

      .card-combo {
        flex: 0 1 100% !important;

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        .card-img {
          width: 130px !important;
          height: 130px !important;
        }
      }
    }
  }

  .section-products {
    .product-title {
      span {
        font-size: 12px !important;
      }
    }

    .content-products {
      .product {
        // padding: 20px 0 0 0 !important;

        .product-content {
          .card-product {
            flex: 0 1 100%;

            .card-body {
              // padding: 10px !important;
            }

            .card-title {
              margin: 0 !important;
              font-size: 14px !important;
            }

            .card-description {
              margin: 10px 0 !important;
              font-size: 12px !important;
              max-height: 50px !important;
            }

            .card-price {
              font-size: 14px !important;
            }

            .card-img {
              width: 130px !important;
              height: 130px !important;
            }
          }
        }
      }
    }
  }

  .section-beverages {
    .title-beverages {
      padding: 0 20px !important;

      label {
        font-size: 16px !important;
      }

      span {
        font-size: 12px !important;
      }
    }

    .beverage-content {
      // padding: 0 10px !important;

      .card-category {
        flex: 0 1 calc(50% - 22px);

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-img {
          width: 130px !important;
          height: 130px !important;
        }
      }

      .card-beverage {
        flex: 0 1 calc(50% - 22px);

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        .card-img {
          width: 130px !important;
          height: 130px !important;
        }
      }
    }
  }

  .section-order-preview {
    width: 100% !important;
  }
  .section-footer {
    width: 100% !important;

    .info-footer {
      padding: 10px !important;
      flex-direction: column !important;

      .contacts {
        width: 100% !important;

        .buttons {
          flex-direction: column !important;

          .button {
            width: 100%;
          }

          label {
            font-size: 12px !important;
          }
        }
      }

      .payments {
        width: 100% !important;

        .methods {
          align-items: center !important;
          justify-content: center !important;

          .method {
            flex: 0 1 calc(25% - 22px);

            img {
              width: 70px !important;
              height: 40px !important;
            }

            label {
              display: none;
            }
          }
        }
      }
    }
  }

  .modal-companies {
    .location-methods {
      flex-direction: column !important;

      .left-side {
        width: 100% !important;
      }

      .center {
        width: 100% !important;
      }

      .right-side {
        width: 100% !important;
      }

      .separator {
        text-align: center;
      }
    }
  }

  .builder {
    margin: 0 !important;
    //height: 100vh !important;

    .modal-content {
      border-radius: unset !important;
      //height: 100% !important;

      .modal-header {
        color: #ffffff !important;
        padding: 10px !important;
        border-radius: unset !important;
        background-color: $button-color-default !important;

        h5 {
          visibility: hidden !important;

          &:before {
            content: "Voltar";
            font-size: 18px !important;
            font-weight: 600 !important;
            visibility: visible !important;
          }
        }

        button {
          display: none !important;
        }
      }

      .modal-body {
        flex-direction: column !important;
        flex-flow: column-reverse !important;
        // height: 100% !important;
        // max-height: 100% !important;
        justify-content: flex-end !important;
        padding-bottom: 60px;

        .left-side-builder {
          margin: 6px 0 0 0 !important;
          width: 100% !important;
          // height: 60vh !important;

          .card-size {
            // flex: 0 1 calc(50% - 22px);

            .card-body {
              padding: 10px !important;

              .card-title {
                font-size: 14px !important;
              }

              .card-description {
                font-size: 12px !important;
                // margin: 0 !important;
                max-height: 60px !important;
              }

              .card-price {
                font-size: 14px !important;
              }
            }

            .card-img {
              width: 100px !important;
              height: 90px !important;
            }

            .card-description {
              margin: 0 !important;
            }
          }

          .card-flavour {
            flex: 0 1 calc(100% - 22px);

            .card-body {
              padding: 8px !important;

              .card-title {
                font-size: 14px !important;
              }

              .card-price {
                font-size: 14px !important;
              }
            }

            .card-img {
              width: 80px !important;
              height: 80px !important;
            }
          }

          .card-ingredient {
            flex: 0 1 calc(50% - 22px);

            .card-body {
              padding: 8px !important;
              justify-content: center;
              align-items: center;

              .card-title {
                font-size: 14px !important;
              }

              .card-description {
                font-size: 12px !important;
                margin: 0 !important;
                max-height: 60px !important;
              }
            }
          }

          .card-additional {
            flex: 0 1 calc(100% - 22px);

            .card-body {
              padding: 8px !important;

              .card-title {
                font-size: 14px !important;
              }

              .card-description {
                font-size: 12px !important;
                margin: 0 !important;
                max-height: 60px !important;
              }

              .card-price {
                font-size: 14px !important;
              }
            }
          }

          .card-optional {
            flex: 0 1 calc(50% - 22px);

            .card-body {
              padding: 8px !important;

              .card-title {
                font-size: 14px !important;
              }

              .card-description {
                font-size: 12px !important;
                margin: 0 !important;
                max-height: 60px !important;
              }

              .card-price {
                font-size: 14px !important;
              }
            }
          }

          .card-beverage {
            flex: 0 1 calc(100% - 22px);

            .card-body {
              padding: 8px !important;

              .card-title {
                font-size: 14px !important;
              }

              .card-price {
                font-size: 14px !important;
              }
            }

            .card-img {
              width: 80px !important;
              height: 80px !important;
            }
          }

          .amount-product-flavour {
            .title {
              font-size: 14px !important;
            }

            .button-group {
              label {
                font-size: 30px !important;
              }

              svg {
                font-size: 30px !important;
              }
            }
          }
        }

        .right-side-builder {
          margin: 0 !important;
          width: 100% !important;
          // height: 40vh !important;

          .card {
            border: none !important;
            height: 100% !important;
            background-color: transparent !important;

            .card-img-top {
              display: none;
            }

            .card-body {
              display: flex !important;
              flex-direction: column-reverse !important;
              flex-wrap: nowrap !important;
              // padding: 8px !important;
              width: 100% !important;

              .card-info {
                // width: 65% !important;
                padding: 20px 8px;

                .card-title {
                  margin-bottom: 6px !important;
                }

                .card-description {
                  // margin: 0 !important;
                  overflow-y: auto !important;
                  // max-height: 80px !important;
                }
              }

              .card-image-right {
                width: 100% !important;
                display: flex !important;
              }
            }

            .card-amount-flavour {
              display: flex !important;
              flex-direction: column !important;
              // padding: 8px !important;
              width: 100% !important;

              .card-image-top {
                display: flex !important;
              }

              .card-info {
                width: 100% !important;
                padding: 8px;

                .card-title {
                  margin: 6px 0 !important;
                  font-weight: 600 !important;
                }

                .card-description {
                  margin: 10px 0 !important;
                  font-size: 14px !important;
                  overflow-y: auto !important;
                  max-height: 100px !important;
                  font-weight: 300 !important;
                }

                .card-price {
                  margin: 6px 0 !important;
                }
              }
            }
          }
        }

        .center-builder {
          //height: 100% !important;
          margin-top: 16px;

          .card {
            flex-direction: column !important;

            .card-img-top {
              display: block !important;
            }

            .card-body {
              width: 100% !important;

              .card-info {
                width: 100% !important;
                padding: 8px;

                .card-price {
                  display: inline !important;
                }
              }

              .card-image-right {
                display: none !important;
              }
            }
          }
        }
      }

      .modal-footer {
        padding: 0 !important;
        position: fixed;
        bottom: 0;
        width: 100%;

        .footer-buttons {
          .button-group {
            label {
            }

            svg {
              font-size: 20px !important;
            }
          }

          .button-footer-button-right {
            //svg {
            //  display: none !important;
            //}

            label {
              font-size: 14px !important;
              font-weight: 550 !important;

              height: 100% !important;
              justify-content: center !important;
              border-radius: 8px !important;
            }
          }
        }
      }
    }
  }

  .modal-flavours {
    //height: 100vh !important;
    align-content: baseline;

    .modal-content {
      //height: 100vh !important;
    }

    .card-flavours {
      height: 102px;
      flex: 0 1 calc(100% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 40px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 100px !important;
        height: 100px !important;
      }
    }
  }

  .modal-ingredients {
    .card-ingredient {
      flex: 0 1 calc(50% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 60px !important;
        }
      }
    }

    .card-optional {
      flex: 0 1 calc(100% - 22px);

      .card-body {
        padding: 8px !important;
        flex-direction: row !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 60px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }
    }
  }

  .modal-beverages {
    .card-category {
      label {
        width: 50% !important;
      }
    }

    .card-beverage {
      flex: 0 1 calc(100% - 22px);

      .card-body {
        padding: 4px 8px !important;

        .card-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 200px;
          font-size: 14px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 90px !important;
        height: 90px !important;
        background-color: #e5e5e5 !important;
      }
    }
  }

  .modal-companies {
    .location-methods {
      flex-direction: column !important;

      .left-side {
        width: 100% !important;
      }

      .center {
        width: 100% !important;
      }

      .right-side {
        width: 100% !important;
      }

      .separator {
        text-align: center;
      }
    }
  }

  .modal-payment-methods {
    .address {
      justify-content: space-around !important;

      .icon-map {
        width: unset !important;
      }

      .description {
        width: unset !important;
      }
    }

    .change-button {
      width: unset !important;
    }

    .method {
      flex: 0 1 calc(100% - 22px);
    }
  }

  .modal-payment-change {
    .change,
    .change-input {
      flex: 0 1 calc(50% - 22px);
    }
  }

  .modal-order-preview {
    margin: 0 !important;

    .modal-content {
      height: 100% !important;
    }

    .order-content {
      max-height: 400px !important;
    }
  }

  .modal-order-list {
    .title-no-orders {
      font-size: 14px !important;
    }

    .subtitle-no-orders {
      font-size: 12px !important;
    }
  }

  .modal-order-progress {
    .lbl-timeline-error {
      font-size: 14px !important;
    }

    .vertical-timeline {
      .vertical-timeline-element-content {
        .vertical-timeline-element-title {
          font-size: 14px !important;
        }

        .vertical-timeline-element-subtitle {
          font-size: 12px !important;
        }
      }

      .vertical-timeline-element-date {
        color: #ffffff !important;
        margin: 10px 0 0 0 !important;
        text-align: right;
      }
    }
  }
}

// 380px
@media #{$max-mobile-sm} {
  .section-navbar {
    .bm-burger-button {
      display: inline !important;
    }
  }

  .section-header {
    .top-side {
      .left-side {
        display: none !important;
      }

      .center {
        .img-logo {
          // height: 120px;
          max-width: 150px;
        }
      }

      .right-side {
        display: none !important;
      }
    }

    .bottom-side {
      .left-side {
        display: none !important;
      }

      .center {
        display: none !important;
      }

      .right-side {
        display: none !important;
        margin: 0 !important;
        border-radius: unset !important;
        align-items: center !important;
        justify-content: space-around !important;
      }

      .main-option {
        .option-title {
          font-size: 12px !important;
          text-transform: uppercase;
        }

        .option-subtitle {
          font-size: 10px !important;
        }
      }

      .separator-option {
        width: 1px;
        height: 80px;
        background-color: #6b6b6b;
      }
    }
  }

  .section-content {
    .left-side-content {
      width: 100% !important;
    }

    .right-side-content,
    .right-side-content-fixed {
      display: none !important;
    }

    .my-orders {
      display: none !important;
    }
  }

  .section-main {
    .menu-options,
    .menu-options-fixed {
      width: 100% !important;
      justify-content: flex-start !important;

      label {
        padding: 10px !important;
        font-size: 14px !important;
      }
    }

    .main-options {
      width: 100%;

      .main-option {
        padding: 0 10px !important;
        align-items: center !important;
        justify-content: center !important;

        .option-badge {
          font-size: 10px;
        }

        .color-store {
          color: $button-color-default;
        }
      }

      .separator-option {
        margin-top: 20px !important;
      }

      .main-option--company-name {
        top: -40px;
        width: 100px;
        font-size: 10px !important;
      }

      .main-option--status-company {
        top: -40px;
        width: 80px;

        label {
          font-size: 10px;
          padding: 2px 4px;
        }
      }
    }
  }

  .section-combos {
    .combos-title {
      padding: 0 20px !important;

      label {
        font-size: 16px !important;
      }

      span {
        font-size: 12px !important;
      }
    }

    .combos-content {
      // padding: 0 10px !important;

      .card-combo {
        flex: 0 1 100% !important;

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 12px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 10px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 12px !important;
        }

        .card-img {
          width: 120px !important;
          max-height: 120px !important;
        }
      }
    }
  }

  .section-products {
    .product-title {
      padding: 0 20px !important;

      label {
        font-size: 16px !important;
      }

      span {
        font-size: 12px !important;
      }
    }

    .content-products {
      .product {
        padding: 20px 0 0 0 !important;
      }

      .card-product {
        flex: 0 1 100%;

        .card-body {
          padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 12px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 10px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 12px !important;
        }

        .card-img {
          width: 120px !important;
          max-height: 120px !important;
        }
      }
    }
  }

  .section-beverages {
    .beverage-title {
      padding: 0 20px !important;

      label {
        font-size: 16px !important;
      }

      span {
        font-size: 12px !important;
      }
    }

    .content-beverages {
      // padding: 0 10px !important;

      .card-beverage {
        flex: 0 1 calc(50% - 22px);

        .card-body {
          // padding: 10px !important;
        }

        .card-title {
          margin: 0 !important;
          font-size: 14px !important;
        }

        .card-description {
          margin: 10px 0 !important;
          font-size: 12px !important;
          max-height: 50px !important;
        }

        .card-price {
          font-size: 14px !important;
        }

        .card-img-top {
          max-height: 160px !important;
        }
      }
    }
  }

  .section-order-preview {
    width: 100% !important;
  }

  .section-footer {
    width: 100% !important;

    .info-footer {
      padding: 10px !important;
      flex-direction: column !important;

      .contacts {
        width: 100% !important;

        .buttons {
          flex-direction: column !important;

          .button {
            width: 100%;
          }

          label {
            font-size: 12px !important;
          }
        }
      }

      .payments {
        width: 100% !important;

        .methods {
          align-items: center !important;
          justify-content: center !important;

          .method {
            flex: 0 1 calc(20% - 22px);

            img {
              width: 70px !important;
              height: 40px !important;
            }

            label {
              display: none;
            }
          }
        }
      }
    }
  }

  .modal-builder {
    margin: 0 !important;
    //height: 100vh !important;

    .modal-content {
      border-radius: unset !important;
      //height: 100% !important;
    }

    .modal-header {
      color: #ffffff !important;
      padding: 10px !important;
      border-radius: unset !important;
      background-color: $button-color-default !important;

      h5 {
        visibility: hidden !important;

        &:before {
          content: "Voltar";
          font-size: 16px !important;
          font-weight: 600 !important;
          visibility: visible !important;
        }
      }

      button {
        display: none !important;
      }
    }

    .modal-body {
      flex-direction: column !important;
      flex-flow: column-reverse !important;
      // height: 100% !important;
      // max-height: 100% !important;
      justify-content: flex-end !important;

      .left-side-builder {
        margin: 6px 0 0 0 !important;
        width: 100% !important;
        border: none !important;
        border-radius: unset !important;
        // height: 60vh !important;

        .card-size {
          // flex: 0 1 calc(50% - 22px);

          .card-body {
            // padding: 10px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }

          .card-description {
            margin: 0 !important;
          }
        }

        .card-flavour {
          flex: 0 1 calc(100% - 22px);

          .card-body {
            // padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }

          .card-img {
            padding: 4px;
            width: 80px !important;
            height: 80px !important;
          }
        }

        .card-ingredient {
          flex: 0 1 calc(50% - 22px);

          .card-body {
            padding: 8px !important;
            justify-content: center;
            align-items: center;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }
          }
        }

        .card-additional {
          flex: 0 1 calc(100% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }
        }

        .card-optional {
          flex: 0 1 calc(50% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-description {
              font-size: 12px !important;
              margin: 0 !important;
              max-height: 60px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }
        }

        .card-beverage {
          flex: 0 1 calc(100% - 22px);

          .card-body {
            padding: 8px !important;

            .card-title {
              font-size: 14px !important;
            }

            .card-price {
              font-size: 14px !important;
            }
          }

          .card-img {
            width: 80px !important;
            height: 80px !important;
          }
        }

        .amount-product-flavour {
          .title {
            font-size: 14px !important;
          }

          .button-group {
            label {
              font-size: 30px !important;
            }

            svg {
              font-size: 30px !important;
            }
          }
        }
      }

      .right-side-builder {
        margin: 0 !important;
        width: 100% !important;
        // height: 40vh !important;

        .card {
          border: none !important;
          height: 100% !important;
          background-color: transparent !important;

          .card-img-top {
            display: none;
          }

          .card-body {
            display: flex !important;
            flex-direction: row !important;
            flex-wrap: nowrap !important;
            // padding: 8px !important;
            width: 100% !important;

            .card-info {
              // width: 65% !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: space-between !important;
              padding: 8px;

              .card-title {
                margin: 0 !important;
                font-size: 18px !important;
              }

              .card-description {
                margin: 0 !important;
                overflow-y: auto;
                max-height: 60px;
                font-size: 12px !important;
              }

              .card-price {
                margin-top: 4px !important;
                display: flex !important;
              }
            }

            .card-image-top {
              display: flex !important;
            }

            .card-image-right {
              width: 35% !important;
              display: flex !important;
            }
          }

          .card-amount-flavour {
            display: flex !important;
            flex-direction: column !important;
            // padding: 8px !important;
            width: 100% !important;

            .card-image-top {
              display: flex !important;
            }

            .card-info {
              width: 100% !important;
              padding: 8px;

              .card-title {
                margin: 6px 0 !important;
                font-weight: 600 !important;
              }

              .card-description {
                margin: 0 !important;
                font-size: 14px !important;
                overflow-y: auto !important;
                font-weight: 300 !important;
              }

              .card-price {
                margin: 6px 0 !important;
              }
            }
          }
        }
      }

      .center-builder {
        height: 100% !important;
        margin-top: 16px;

        .card {
          flex-direction: column !important;

          .card-img-top {
            display: block !important;
          }

          .card-body {
            width: 100% !important;

            .card-info {
              width: 100% !important;
              justify-content: flex-start !important;
              padding: 8px;
            }

            .card-image-right {
              display: none !important;
            }
          }
        }
      }
    }

    .modal-footer {
      .footer-buttons {
        .button-group {
          label {
            font-size: 15px !important;
          }

          svg {
            font-size: 20px !important;
          }
        }

        .button-footer-button-right {
          //svg {
          //  display: none !important;
          //}

          label {
            font-size: 11.5px !important;
            font-weight: 550 !important;

            height: 100% !important;
            justify-content: center !important;
            border-radius: 8px !important;
          }
        }
      }
    }
  }

  .modal-flavours {
    .modal-content {
      //height: 100vh !important;
    }

    .card-flavours {
      flex: 0 1 calc(100% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 40px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 100px !important;
        height: 100px !important;
      }
    }
  }

  .modal-ingredients {
    .card-ingredient {
      flex: 0 1 calc(50% - 22px);

      .card-body {
        padding: 8px !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 60px !important;
        }
      }
    }

    .card-optional {
      flex: 0 1 calc(100% - 22px);

      .card-body {
        padding: 8px !important;
        flex-direction: row !important;

        .card-title {
          font-size: 14px !important;
        }

        .card-description {
          font-size: 12px !important;
          margin: 0 !important;
          max-height: 60px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }
    }
  }

  .modal-beverages {
    .card-beverage {
      flex: 0 1 calc(100% - 22px);

      .card-body {
        padding: 4px 8px !important;

        .card-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 200px;
          font-size: 14px !important;
        }

        .card-price {
          font-size: 14px !important;
        }
      }

      .card-img {
        width: 90px !important;
        height: 90px !important;
        background-color: #e5e5e5 !important;
      }
    }
  }

  .modal-companies {
    .location-methods {
      flex-direction: column !important;

      .left-side {
        width: 100% !important;
      }

      .center {
        width: 100% !important;
      }

      .right-side {
        width: 100% !important;
      }

      .separator {
        text-align: center;
      }
    }
  }

  .modal-payment-methods {
    .icon-map {
      width: unset !important;
    }

    .description {
      width: unset !important;
    }

    .change-button {
      width: unset !important;
    }

    .method {
      flex: 0 1 calc(100% - 22px);
    }
  }

  .modal-payment-change {
    .change,
    .change-input {
      flex: 0 1 calc(50% - 22px);
    }
  }

  .modal-order-preview {
    margin: 0 !important;

    .modal-content {
      height: 100% !important;
    }

    .order-content {
      max-height: 550px !important;
    }
  }

  .modal-addresses {
    .address {
      .description {
        font-size: 14px !important;
      }
    }
  }
}

@media #{$max-mobile-height-xl} {
}

@media #{$max-mobile-height-lg} {
}

@media #{$max-mobile-height-lm} {
  .address-suggestions {
    height: 100%;
    overflow-y: auto;
  }
}

@media #{$max-mobile-height-md} {
}

@media #{$max-mobile-height-ms} {
}

@media #{$max-mobile-height-sm} {
}

@media #{$max-mobile-height-xs} {
}

@import "../../assets/scss/Variables.scss";

.component-input {
  border: 1px solid $border-color;
  border-radius: $button-border-radius;
  padding: 10px;
  text-align: center;
  width: 100%;
}

input[disabled] {
  background-color: #dadada
}
@import "../../assets/scss/Variables";

.addresses-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  max-height: 100vh;

  border-radius: 20px 20px 0 0;
  background-color: #fff;
  text-align: center;

  .addresses-info--header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid rgb(222, 222, 222);
    position: relative;
    width: 100%;
    font-size: 18px;

    svg {
      position: absolute;
      left: 10px;
      padding: 10px;
      cursor:pointer;
    }
  }

  .addresses-info--body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .addresses-info--body--top {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding-top: 10px;
      gap: 10px;
      max-height: 75vh;
      overflow-y: auto;
      box-shadow: inset -5px -30px 25px -30px rgba(0, 0, 0, 0.2);

      .addnress-info--no-address {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }

    .addresses-info--body--button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;


      button {
        border: none;
        font-size: $button-font-size;
        width: 80%;
        height: 40px;
        border-radius: $button-border-radius;
        background-color: $button-color-default;
        color: #fff;
        margin-top: 10px;
      }
    }
  }
}

.addresses-info-active {
  animation: moveFromRight 0.3s;
  visibility: visible;
  right: 0;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes moveToBottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.full-content-loading {
  top: 0;
  left: 0;
  z-index: 2000;
  height: 100vh;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  .filter {
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1001;
    position: absolute;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.308);
  }

  .ring-loading,
  .clock-loading {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    label {
      color: #e3e3e3;
      margin-top: 40px;
      font-size: 18px;
    }
  }

  .fade-loading {
    width: 100%;
    height: 100vh;
    display: flex;
    z-index: 1001;
    position: fixed;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(4px);
    background-color: rgba(101, 101, 101, 0.35);

    label {
      color: #ffffff;
      margin-top: 60px;
      font-size: 18px;
      text-align: center;
    }
  }
}

@import "../../assets/scss/Variables";

.schedule-week-day--working-time {
  border: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 5px;
  border-radius: $border-radius;

  .schedule-week-day--working-time--top {
    span {
      font-size: 12px;
      font-weight: 700;
    }
  }
  .schedule-week-day--working-time--divider {
    display: flex;
    width: 100%;
    height: 2px;
    background-color: $border-color;
  }
  .schedule-week-day--working-time--bottom {
    span {
      font-size: 12px;
      font-weight: 400;
    }
  }


}

.selected-schedule-week-day {
  border: 1px solid $button-color-default;
}

.closed-schedule-week-day {
  background-color: #c6c6c6;
  cursor: not-allowed;

}
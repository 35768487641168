@import "../../assets/scss/Variables";


.order-preview {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #fff;
  max-width: 100%;
  max-height: 100vh;
  border-radius: 20px 20px 0 0;


  .order-preview--header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid rgb(222, 222, 222);
    position: relative;
    width: 100%;

    h5{
      font-size: 16px;
    }

    svg {
      position: absolute;
      left: 10px;
      padding: 2px 12px;
      cursor: pointer;
    }
  }

  .order-preview--body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .order-preview--body--top {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 0 10px;
      cursor: pointer;
      height: calc(40vh);
      overflow-y: auto;

      .order-preview--body--top--button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        padding: 10px 0;

        span {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

    .order-preview--body--bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-height: calc(50vh - 130px);
      overflow: auto;

      .order-preview--body--bottom--divider {
        display: flex;
        width: 100%;
        height: 1px;
        background-color: $border-color;
      }

      .order-preview--body--bottom--benefit-item {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        box-shadow: 0 -6px 16px 1px rgba(0, 0, 0, 0.2);
      }

      .order-preview--body--bottom--order-total-info {
        display: flex;
        width: 100%;
        padding: 5px 0;
        justify-content: center;
        align-items: flex-start;
      }

      .order-preview--body--bottom--delivery-info {
        display: flex;
        width: 100%;
        padding: 5px 20px;
        justify-content: center;
        align-items: center;
      }

      .order-preview--body--bottom--delivery-observation {
        display: flex;
        width: 100%;
        padding: 5px 20px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .order-preview--footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;


    padding: 5px 0;

    .order-preview--footer--button {
      width: 90%;
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}

@import "../../assets/scss/Variables";

.beverage-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .beverage-item--data {
    display: flex;
    width: 100%;
    height: 100%;

    align-items: flex-start;
    justify-content: center;
    font-size: 12px;
    padding: 10px;
    line-height: 14px;

    .beverage-item--data--left {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .beverage-item--data--left--image {
        width: 60px;
        height: 60px;
        border-radius: $button-border-radius;
      }

      .beverage-item--data--left--edit-button {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        top: -2px;
        right: -2px;
        box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);

        background-color: #FFF;
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }

    }

    .beverage-item--data--center {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      justify-content: center;
      padding-left: 10px;

      .beverage-item--data--center--category {
        font-weight: 700;
        text-align: left;
        text-wrap: normal;
      }

      .beverage-item--data--center--name {
        font-weight: 400;
      }


    }

    .beverage-item--data--right {
      display: flex;
      height: inherit;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      min-width: 60px;
    }

  }

  .beverage-item--divisor {
    display: flex;
    width: 90%;
    height: 1px;
    background-color: $border-color;

  }
}

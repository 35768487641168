@import "../../assets/scss/Variables.scss";

.personal-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  //border-left: 1px solid rgb(232, 232, 232);
  position: absolute;
  top: 0;
  right: 100%;
  visibility: hidden;

  .personal-info--header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid rgb(222, 222, 222);
    position: relative;
    width: 100%;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 1px;

    svg {
      position: absolute;
      left: 10px;
      cursor: pointer;

      padding:10px;
    }
  }

  .personal-info--body {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;

    .personal-info--body--input {
      position: relative;
      padding-top: 13px;
      width: 90%;
    }

    .personal-info--body--input input {
      width: 100%;
      height: 50px;
      border-radius: 3px;
      padding: 0 10px;
      background-color: transparent;
      border: 1px solid rgb(222, 222, 222);
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -webkit-appearance: none;
    }

    .personal-info--body--input input:focus {
      border-bottom: 2px solid rgb(232, 232, 232);
    }

    .personal-info--body--input input::placeholder {
      color: transparent;
    }

    .personal-info--body--input label {
      pointer-events: none;
      position: absolute;
      top: 2px;
      left: 5px;
      background-color: #fff;
      padding: 2px;
      margin-top: 20px;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
    }

    .personal-info--body--input input:required:invalid + label {
      color: #dc3545;
    }

    .personal-info--body--input input:focus:required:invalid {
      border-bottom: 2px solid #dc3545;
    }

    .personal-info--body--input input:required:invalid + label:before {
      content: "*";
    }

    .personal-info--body--input input:focus + label,
    .personal-info--body--input input:not(:placeholder-shown) + label {
      font-size: 13px;
      margin-top: 0;
      color: #dc3545;
    }

    .personal-info--body--button {
      position: relative;
      padding-top: 13px;
      width: 90%;
      .component-button {
        p {
          font-size: 16px;
          color: $button-color-default;
        }   }

      button {
        width: 100%;
        height: 50px;
        padding: 0 10px;
        background-color: #fff;
        border: 1px solid $button-color-default;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -webkit-appearance: none;
      }
    }

    .required-input {
      color: red;
    }

    .required-input input {
      border: 2px solid red;
      border-radius: 3px;
    }
  }

  .personal-info--need-update {
    position: relative;
    padding-top: 13px;
    width: 90%;


    .component-button {
      p {
        font-size: 16px;
        color: #fff;
      }   }
    button {
      height: 50px;
      width: 100%;
      border: none;

    }
  }
}

.personal-info-active {
  animation: moveFromRight 0.3s;
  visibility: visible;
  right: 0;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@import "../../assets/scss/Variables";

.payment-category-items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;


  .payment-category-items--category {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    width: 100%;
    //padding: 10px 0;
    padding-bottom: 10px;
    gap: 5px;


    .payment-category-items--category--title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      margin-top: 10px;
    }

    .payment-category-items--category--payment-method {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      gap: 10px;
      width: 100%;
      border: 1px solid $border-color;
      border-radius: $button-border-radius;
      padding: 5px;
      cursor: pointer;

      img {
        width: 44px;
        height: 36px;

        &#card {
          width: 45px;
          height: 28px;
        }

        &#pix {
          width: 24px;
          margin: 0 8px 0 8px;
          height: 28px;
        }
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .payment-category-items--category--payment-method--change {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      border: 1px solid $border-color;
      border-radius: $button-border-radius;
      padding: 5px;

      span {
        font-size: 12px;
      }

      .payment-category-items--category--payment-method--change--input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        border: 1px solid $border-color;
        border-radius: $button-border-radius;
        padding: 5px;
        text-align: center;
      }

    }

    .payment-category-items--category--payment-method--selected {
      border: 1px solid red;
    }
  }
}
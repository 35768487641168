@import "../../../assets/scss/Variables";

.modal-order-finisher {
  //  display: flex;
  //  flex-direction: column;
  //  align-items: flex-end;
  //  justify-content: flex-end;
  //  background-color: rgba(0, 0, 0, 0.4);
  //  //position: absolute;
  //  width: 100%;
  ////  height: 100vh;
  //  top: 0;
  //  left: 0;
  //  z-index: 20;
  //  border-top-left-radius: 20px;
  //  border-top-right-radius: 20px;
  //
  //  padding: 20px 0 0 0;
  //  animation: moveFromBottom 0.3s;
  //  background-color: #fff;
  //  align-items: center;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //width: 100vw;
  max-width: 100%;
  max-height: 100vh;

  .modal-order-finisher--content--header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 10px;
    padding: 0;
    border: none;
    margin: 0;


    .delivery-info {
      width: 100vw;
      padding:10px 20px 0 20px;
    }

    .modal-order-finisher--content--header--delivery-timer {
      align-self: flex-start;
      font-weight: 600;
      height: 70px;

      font-size: 10px;
      line-height: 14px;
      padding: 20px;
    }
  }
  .modal-order-finisher--content--body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top: 0;

    .modal-order-finisher--content--body--section {
      border-top: 1px solid $border-color;
      align-self: flex-start;
      padding: 20px;
      //margin-top: 20px;
      width: 100%;
      font-weight: 700;
      line-height: 19px;
      font-size: 14px;

    }

    .modal-order-finisher--content--body--section--cashback {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 0 10px;
      flex-direction: row;
      label {
        width: 100%;
        font-size: 14px;
      }
      input {
        width: 10%;
      }

      .modal-order-finisher--content--body--section--cashback input:checked ~ .modal-order-finisher--content--body--section--cashback--checkbox {
        background-color: red;
      }


      //input[type="checkbox"].modal-order-finisher--content--body--section--cashback--checkbox:checked {
      //  border-color: red;
      //  background-color:red;
      //}
    }
  }
  //.modal-order-finisher--content--footer {
  //  width: 90%;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  gap: 10px;
  //  padding: 20px 0;
  //}

  .modal-order-finisher--content--footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    gap: 10px;
    border: none;
    flex-wrap: nowrap;
    margin: 0 auto;
    .modal-order-finisher--content--footer--cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      button {
        width: 100%
      }
    }

    .modal-order-finisher--content--footer--confirm {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

    }
  }

}


@keyframes moveFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

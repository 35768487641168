@import "../../assets/scss/Variables.scss";

.modal-beverages {
  min-width: 100vw;
  max-height: 100%;

  .modal-body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-y: auto;
    max-height: 70vh;

    .card-category {
      width: 100%;
      display: flex;
      flex-direction: column;

      label {
        width: 30%;
        margin-left: 10px;
        // border-bottom: 2px solid $button-color-default;
      }

      .card-category-content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        gap: 10px;
        .card-beverage-multi {
          width: 100%;
          height: 78px;
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          align-items: center;
          border: 1px solid rgba(0, 0, 0, 0.176);
          border-radius: 6px;
          cursor: pointer;
          .card-beverage-multi--left {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            text-size-adjust: 100%;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-style: normal;
            line-height: 21px;
            text-align: start;
            text-overflow: ellipsis;
            text-wrap-mode: nowrap;
            white-space-collapse: collapse;
            width: 65%;
          }
          .card-beverage-multi--right {
            width: 35%;
            .card-beverage-multi--right--number-control {
              width: auto;
            }
          }
        }
      }
    }

    .card-beverage {
      display: flex;
      border-radius: 6px;
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 10px 10px 0 10px;
      justify-content: space-between;
      // border: 1px solid red;
      .card-img {
        width: 120px;
        object-fit: cover;
        border-radius: 0 5px 5px 0;
        background-color: transparent;
      }

      .card-body {
        display: flex;
        padding: 16px 12px;
        flex-direction: column;
        justify-content: space-around;

        p {
          margin: 0;
        }

        .card-title {
          margin: 0;
          font-size: 16px;
          font-weight: 600;
        }

        .card-description {
          overflow-y: auto;
          font-size: 12px;
          font-weight: 300;
          max-height: 20px;
          font-family: "Rubik", serif !important;
        }

        .card-price {
          font-size: 16px;
          font-weight: 800;
          font-family: "Rubik", serif !important;
        }
      }

      .card-preview-image {
        position: relative;

        .flag-image {
          position: absolute;
          right: 0;
          top: 10px;
          z-index: 2;

          .flag-image--body {
            max-width: 150px;
            height: 20px;
            position: relative;
            box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);

            div {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              label {
                margin: 0;
                padding: 0 4px;
                font-size: 10px;
                color: #ffffff;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            &:after {
              content: "";
              position: absolute;
              left: -8px;
              bottom: 0;
              width: 0;
              height: 0;
              border-right-style: solid;
              border-right-width: 8px;
              border-right-color: inherit;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
            }
          }
        }

        .card-img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 5px;
          background-color: #e5e5e5;
        }
      }
    }
  }

  .beverage-selected {
    color: #04a53d;
    border: 1px solid #04a53d !important;
  }
}

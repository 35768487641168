@import "../../../assets/scss/Variables.scss";

.modal-address-not-found {
  min-width: 100%;
  max-height: 100%;
  display: flex;
  position: fixed;
  bottom: 30% !important;
  margin: 0 !important;

  .modal-content {
    border-radius: 20px !important;
    //border-radius: unset !important;
  }

  .modal-address-not-found--company-list{
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    max-height: 50vh;

    .modal-address-not-found--company-list--tittle{
      font-weight: 600;
      width: 100%;
      text-align: center;
    }
    .modal-address-not-found--company {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      //border-bottom: 1px solid #dee2e6;
      padding: 16px;

      .modal-address-not-found--company--img{
        display: flex;
        width: 65px;
        align-items: center;
        display: flex;


        border-radius: 12px;
        cursor: pointer;
        img {
          min-width: 100%;
          min-height: 100%;
          max-width: 100%;
          max-height: 100%;

          border-radius: 12px;
        }
      }

      .modal-address-not-found--companies--company{
        display: flex;
        flex-direction: column;
        min-width: 220px;
        cursor: pointer;

        .modal-address-not-found--companies--company--name{
          display: flex;
          width: 100%;

          font-weight:700;
        }

        .modal-address-not-found--companies--company--modalities{
          display: flex;
          width: 100%;
          height:fit-content;
          flex-direction: row;

          .modal-address-not-found--companies--company--modalities--active{
            display: flex;
            padding-right: 14px ;
            width:fit-content;
            height: fit-content;
            font-size: 14px;

          }
        }
      }
    }
  }


  .modal-address-not-found--modal-footer {
    border: 0;
  }
}
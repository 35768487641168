@import "../assets/scss/Variables.scss";

.section-header {
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  background-color: #cccccc;
  justify-content: space-between;
  height: 200px;
  background-size: cover !important;
  position: relative;

  .img-logo {
    height: 150px;
    max-width: 300px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 150px;
      width: auto;

      object-fit: cover;
    }
  }

  .top-side {
    display: flex;
    flex-direction: row;

    .left-side {
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
    }

    .center {
      display: none;
      flex: 1 1 auto;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      img {
        height: 150px;
        width: auto;
      }

      .img-center {
        display: none;
        max-height: 150px;
      }
    }

    .right-side {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-between;

      .buttons {
        display: flex;
        padding: 0 10px;
        justify-content: space-around;

        label {
          margin: 0 10px;
          cursor: pointer;
          color: #000000;
          padding: 5px 20px;
          border-radius: 2px;
          font-size: 14px;
          background-color: #ffffff;
        }

        .username {
          color: #ffffff;
          background-color: #23b441;
        }

        .company {
          color: #ffffff;
          background-color: $button-color-default;
        }

        .signin {
          &:hover {
            border-radius: 3px;
            background-color: darken(#ffffff, 15%);
          }
        }
      }
    }
  }

  .center {
    position: absolute;
    top: 25%;
    left: 25%;
    .center--company {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;

      strong {
        text-shadow: 1px 1px black;
      }
    }
  }

  .bottom-side {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;

    .left-side {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .center {
      flex: 1 1 auto;
      margin-top: 40px;
      display: flex;
      z-index: 100;
      justify-content: center;
    }

    .right-side {
      display: flex;
      flex: 1 1 auto;
      margin: 0 20px 0 20px;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;

      button {
        padding: 10px 30px;
        font-size: 24px;
      }

      .my-orders {
        color: #ffffff;
        cursor: pointer;
        font-size: 20px;
        border-radius: 3px;
        padding: 15px 50px;
        background-color: $button-color-default;
      }
    }

    .main-option {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #6b6b6b;

      .option-title {
        margin-top: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .option-subtitle {
        font-weight: bold;
        font-size: 14px;
        color: #000000;
        font-family: "Rubik", serif !important;
      }

      .color-store {
        color: $button-color-default;
      }
    }

    .separator-option {
      width: 1px;
      height: 80px;
      background-color: #6b6b6b;
    }
  }
}

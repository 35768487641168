@import "../../assets/scss/Variables.scss";

.profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  font-family: "Open sans", sans-serif;
  flex-direction: column;

  .profile--user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
      position: relative;
    width: 100%;
    height: 100vh;
    flex-direction: column;

    .profile--user--header {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
      position: relative;
      border-bottom: 1px solid rgb(232, 232, 232);

      img {
        width: 40px;
        display: flex;
        align-items: center;
        position: absolute;
        left: 20px;
      }

      label {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .profile--user--body {
      display: flex;
      width: 100%;
      flex-direction: column;

      .profile--user--body--menu-option {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50px;
        padding: 5px 0 5px 10px;
        border-bottom: 1px solid rgb(232, 232, 232);
        cursor: pointer;
        .profile--user--body--menu-option--left {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 10%;
        }

        .profile--user--body--menu-option--center {
          display: flex;
          width: 80%;
          flex-direction: column;
          padding-left: 5px;

          .profile--user--body--menu-option--center--top {
            display: flex;
            font-size: 14px;
            font-weight: 600;
          }

          .profile--user--body--menu-option--center--bottom {
            display: flex;
            font-size: 12px;
          }
        }

        .profile--user--body--menu-option--right--label {
          display: flex;
          flex-direction: column;
          width: 20px;
          height: 20px;
          text-align: center;
          align-items: center;
          background-color: $button-color-default;
          color: #FFF;
          font-size: 12px;
          font-weight: 600;
          border-radius: 100%;
          margin-right: 0.5em;
          padding-top: 0.1em;
        }

        .profile--user--body--menu-option--right--spinner {
          display: flex;
          flex-direction: column;
          width: 20px;
          height: 20px;
          margin-right: 0.5em;

        }

        .profile--user--body--menu-option--right {
          display: flex;
          width: 10%;

        }
      }

    }
      .profile--user--body--version {
        position: absolute;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        bottom: 70px;
        right: 10px;
      }
  }

  .profile--no-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    .profile--no-user--button {
      width: 40%;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


@import "../../../assets/scss/Variables";

.modal-new-card {
  .modal-new-card--body {
    padding: 0 30px 20px 30px;

    .modal-new-card--body--top {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .modal-new-card--body--top-secure {
        width: 95px;
        height: 34px;
      }

      .modal-new-card--body--top-card {
        background-color: #6d6d6d;
        transition: background-size .5s, color .5s;
        border-radius: 20px;
        position: relative;
        min-height: 186px;
        max-height: 186px;
        height: 100%;
        width: 100%;
        overflow: hidden;
        padding: 0 24px;
        margin-top: 20px;
        background-repeat: no-repeat;
        background-size: 60% 60%;
        transition-delay: .2s;

        &::after {
          width: calc(100% - 20px);
          height: 173px;
          top: 0;
          left: 0;
          content: ' ';
          border-radius: 0 0 20px 0;
          background-color: #7f7f7f;
          z-index: 1;
          position: absolute;
          background-repeat: no-repeat;
          background-size: 0% 0%;
          transition: background-size .5s, color .5s;
        }

        &.visa {
          background-image: linear-gradient(#002C51, #002C51);


          &::after {
            background-image: linear-gradient(#003663, #003663);
          }
        }

        &.elo {
          background-image: linear-gradient(#ff6d4c, #ff6d4c);

          &::after {
            background-image: linear-gradient(#ff8164, #ff8164);
          }
        }

        &.mastercard {
          background-image: linear-gradient(#ffa429, #ffa429);

          &::after {
            background-image: linear-gradient(#ec9018, #ec9018);
          }
        }

        &.active {
          background-size: 100% 100%;

          &::after {
            background-size: 100% 100%;
          }
        }


        .modal-new-card--body--top-card--content {
          position: relative;
          z-index: 99;

          .modal-new-card--body--top-card--content--chip {
            width: 36px;
            height: 24px;
            background-color: #FBB42C;
            z-index: 99;
            margin-top: 32px;
            border-radius: 5px;
          }

          .modal-new-card--body--top-card--content-card-number {
            font-size: 14px;
            color: #FFF;
            letter-spacing: 3.42px;
            margin-top: 16px;
          }

          .modal-new-card--body--top-card--content--infos {
            display: flex;
            margin-top: 30px;
            align-items: center;
            justify-content: space-between;

            .modal-new-card--body--top-card--content--infos--left {
              h2 {
                font-size: 12px;
                font-weight: 600;
                color: #fff;

                span {
                  font-weight: 400;
                }
              }
            }

            .modal-new-card--body--top-card--content--infos--right {
              .modal-new-card--body--top-card--content--infos--right-card-brand {
                width: 64px;
              }
            }
          }
        }
      }
    }

    .modal-new-card--body--center {
      margin-top: 16px;

      .modal-new-card--body--center-form {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .modal-new-card--body--center-form-row {
          display: flex;
          gap: 10px;
          width: 100%;

          .modal-new-card--body--center-form-row-input {
            position: relative;
            width: 100%;

            h4 {
              font-size: 12px;
              margin: 2px 0;
              color: #666;
            }

            img {
              position: absolute;
              right: 10px;
              width: 38px;
              height: 32px;
              top: calc(38px / 2 + 2px);
              border: none;

            }

            input {
              &::placeholder {
                font-size: 12px;
              }

              &:focus,
              &:hover {
                box-shadow: none;
                border-color: $button-color-default;
              }
            }
          }

          .modal-new-card--body--center-form-row-checkbox {
            margin-top: 20px;
            display: flex;
            gap: 10px;
            align-items: center;

            input {
              cursor: pointer;
            }

            label {
              margin: 0;
              font-size: 12px;
              cursor: pointer;
              color: #666;
            }
          }
        }
      }
    }
  }

  .modal-new-card--footer {
    .modal-new-card--footer-buttons {
      display: flex;
      width: 100%;
      gap: 10px;
    }
  }
}
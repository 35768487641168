.modal-popup {
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;

   .modal-popup--image {
      // padding: 30px 5px;
      padding: 5px;
      background-color: #fff;
      border-radius: 5px;
      img {
         width: 100%;
         height: 100%;
         border-radius: 5px;
      }
   }

   .modal-popup--desc {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
         margin: 0;
         text-align: center;
         word-wrap: normal;
      }
   }

   .modal-popup--close {
      position: absolute;
      top: 8px;
      right: 8px;

      svg {
         color: #c6c6c6;
         width: 20px;
         height: 20px;
      }

      &:hover {
         color: darken(#c6c6c6, 0.8);
      }
   }

   .modal-popup--show-again {
      position: absolute;
      bottom: 0;
      left: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      p {
         margin: 0;
         font-size: 12px;
      }

      input {
         margin-left: 5px;
      }
      input:checked {
         background-color: #342224;
      }
   }
}

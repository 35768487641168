@import "../assets/scss/Variables.scss";

.section-order-preview {
  width: 100%;
  height: 54px;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;

  background-color: #D00505;
  color: #FFFFFF;
  bottom: 62px;
  z-index: 2;

  label {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    padding: 0;
    cursor: pointer;

  }

  svg {
    width: 25px;
    height: 25px;
  }
}

.collapse-item {
  width: 100%;

  .collapse-header {
    width: 100%;
    display: flex;
    color: #000000;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #dcdfe0;
    border-top-left-radius: 3px;
    border-color: darken(#dcdfe0, 1%);

    .lbl-info {
      margin: 0;
      display: flex;
      font-size: 14px;
      flex-direction: column;

      .nro-item {
        margin-left: 6px;
        font-size: 12px;
        font-weight: 200;
      }

      .subtitle {
        font-size: 12px;
      }
    }

    .collapse-header-options {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .lbl-option {
        margin: 0;
        font-size: 12px;
      }

      .lbl-collapse {
        color: #494949;
        padding: 0 4px;
        cursor: pointer;
        margin: 0 0 0 10px;
        border-radius: 4px;

        svg {
          font-size: 14px;
        }
      }
    }
  }

  .collapse-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: flex-start;

    .collapse-content-search-input {
      width: 100%;
      font-size: 14px;
      padding: 2px 12px 0 8px;
    }

    .collapse-card {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin: 10px 10px 0 10px;
      width: 100%;
      .card-img {
        width: 200px;
        object-fit: cover;
        border-radius: 0 3px 3px 0;
        background-color: #e5e5e5;
      }

      .card-body {
        display: flex;
        padding: 16px 12px;
        align-items: center;
        //flex-direction: column;
        //justify-content: space-around;

        p {
          margin: 0;
        }

        .card-title {
          margin: 0;
          font-size: 16px;
          font-weight: 400;
          text-transform: capitalize;
          //white-space: nowrap;

          label {
            font-weight: 900;
          }
        }

        .card-body-content {
          width: 100%;
        }

        .card-description {
          max-height: 80px;
          overflow-y: auto;
          font-size: 14px;
          font-weight: 200;
          margin: 10px 0;
        }

        .card-price {
          font-size: 16px;
          font-weight: 800;
          font-family: "Rubik", serif !important;
        }

        .card-price-exceeded {
          font-size: 12px;
          color: #23b445;
        }
      }
    }
  }

  .collapse-hidden {
    display: none !important;
  }

  .collapse-validation {
    background-color: #ffbac4 !important;

    .lbl-collapse {
      color: #ff3c46 !important;
    }
  }
}

.item-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.item-selected {
  border: 1px solid #04a53d;

  .card-body {
    color: #04a53d;
  }
}

.item-removed-selected {
  border: 1px solid #ff292f;

  .card-title {
    color: #ff292f;
    text-decoration: line-through;
  }
}

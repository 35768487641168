
.order-total-info {
  gap: 5px;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  label {
    margin: 0;
    font-size: 14px;
  }

  span {
    font-size: 18px;
    font-weight: 800;
  }

  .order-total-info-discount{
    color: red;
  }

  .order-total-info--cashback {

    .order-total-info--cashback--use-cashback {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 5px;

      input {
        margin-left: 10px;
      }

      p {
        margin: 0;
        padding: 0;
      }
    }

  }
}